




































































































































import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { Component } from 'vue-property-decorator'
import BaseProjectView from '@/views/BaseProjectView'
import { RestApiError } from '@/providers/RestApiError'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Settings extends BaseProjectView {
  name = "";
  fullAddress = "";
  creationDate = "";
  uuid = "";
  required = required;
  
  get owner(): string {
    return `Project owner: ${(!this.amiOwner ? this.project.owner : "you")}`
  }

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, () => location.reload())
  }

  mounted(): void {
    this.name = this.project.name;
    this.fullAddress = this.project.fullAddress;
    this.uuid = this.project.uuid;    
    this.creationDate = this.dateToLocaleStringWithUserTimezone(this.project.creationDate);
  }

  async updateProject(): Promise<void> {
    const valid = await (this.$refs.rules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    this.apiProvider.updateProject(this.project.id, {
      name: this.name,
      full_address: this.fullAddress,
    })
      .then(project => {
        this.$store.commit('plexus/SELECT_PROJECT', project)
        this.makeToast('Project updated successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  async handleDeleteOk(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.deleteProject()
  }

  async deleteProject(): Promise<void> {
    this.apiProvider.deleteProject(this.project.id)
      .then(() => {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-delete-project')
          this.$router.push({
            path: '/'
          })
          this.makeToast('Project deleted successfully')
          
          this.$nextTick(() => {
            this.$store.commit('plexus/SELECT_PROJECT', null)
          })
        })
      })
      .catch((error: RestApiError) => this.handleRestApiException(error)) 
  }
}
