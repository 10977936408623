





















































import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import lodash from 'lodash'

import BaseProjectView from '@/views/BaseProjectView'
import ReportSectionCard from '@/components/ReportSectionCard.vue'
import { Report, ReportSection } from '@/models/Report'
import { RestApiError } from '@/providers/RestApiError'
import { IDeviceVariables } from '@/models/Device'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ReportSectionCard,
  },
})
export default class ReportSections extends BaseProjectView {
  report: Report | null = null;
  deviceVariables: IDeviceVariables | null = null;
  selectedReportSection: ReportSection | null = null;

  get modalContent(): string {
    return `Are you sure you want remove the report section "${this.selectedReportSection?.name}"?`
  }

  get reportName(): string {
    return this.report != null ? this.report.name : ""
  }

  get relatedName(): string {
    if (this.report == null) {
      return ""
    }

    return `Related to ${this.report.isRelatedToDevice ? 'Device' : 'Mixed Chart'} "${this.report.isRelatedToDevice ? this.report.deviceName : this.report.mixedChartName}"`
  }

  get availableVariables(): string {
    return this.deviceVariables != null ? this.deviceVariables.variables.join(", ") : ""
  }

  get availableVariablesMessage(): string {
    return this.availableVariables != "" ? `Available variables: ${this.availableVariables}` : "No blackbox variables found, you can't create new report sections."
  }

  get reportSections(): ReportSection[] {
    return this.report != null ? this.report.sections : []
  }

  get isLoggedUserOwnerOfReport(): boolean {
    if (this.report == null) return false;

    return this.report.userCreatorId == this.loggedUserId
  }

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.fetchData)
    this.$watch(() => this.$route.params.reportId, this.fetchData)
    window.addEventListener("resize", lodash.debounce(this.resize, 100));
  }

  destroyed(): void {
    window.removeEventListener("resize", lodash.debounce(this.resize, 100));
  }

  resize(): void {
    const elSections = this.$refs.sections as HTMLElement;
    const elVariables = this.$refs.variables as HTMLElement;
    
    if (elSections != null) {
      const sectionsHeightCalc = window.innerHeight - (elVariables == null ? 340 : 235 + elVariables.clientHeight)
      elSections.style.height = `${sectionsHeightCalc.toString()}px`
    }
  }

  async mounted(): Promise<void> {
    await this.fetchData()
    this.resize()
  }

  async fetchData(): Promise<void> {
    if (this.project == null) return;
    
    try {
      this.report = await this.apiProvider.getReport(this.project.id, Number.parseInt(this.$route.params.reportId, 10));
      if (this.report != null) {
        if (!this.amiAdmin && !this.isLoggedUserOwnerOfReport) {
          this.$router.replace({
            name: 'reports'
          })
          return;
        }
        if (this.report.isRelatedToDevice) {
          this.deviceVariables = await this.apiProvider.getDeviceAndVariablesList(this.project.id, this.report.deviceId);
        } else {
          const mc = await this.apiProvider.getMixedChart(this.project.id, this.report.mixedChartId);
          this.deviceVariables = {
            deviceId: mc.id,
            deviceName: mc.name,
            variables: mc.variables,
          };
        }
      }
    } catch (error) {
      this.handleRestApiException(error as RestApiError)
      this.$router.replace({
        name: 'reports'
      })
    }
  }

  async addSection(): Promise<void> {
    if (this.report == null) return;

    this.report.sections.push(new ReportSection({
      name: "",
      start: "chart_start",
      end: "chart_end",
      outputs: [],
    }))
  }

  openDeleteDialog(index: number): void {
    if (this.reportSections[index].id == null || this.reportSections[index].id == 0) {
      this.reportSections.splice(index, 1);
      return;
    }

    this.selectedReportSection = this.reportSections[index].clone();
    this.$root.$bvModal.show('modal-delete-section')
  }

  closeDeleteDialog(): void {
    this.selectedReportSection = null
    this.$root.$bvModal.hide('modal-delete-section')
  }

  async deleteSection(): Promise<void> {
    if (this.selectedReportSection == null || this.selectedReportSection.id === 0) return;
    
    const idToDelete = this.selectedReportSection.id;
    this.apiProvider.deleteReportSection(this.project.id, Number.parseInt(this.$route.params.reportId, 10), idToDelete)
      .then(() => {
        const index = this.reportSections.findIndex(x => x.id === idToDelete)
        if (index > -1) this.reportSections.splice(index, 1)
        
        this.closeDeleteDialog()
        this.makeToast('Device removed successfully')
      })
      .catch(error => this.handleRestApiException(error));
  }
}
