/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseRestApiProvider from '@/providers/BaseRestApiProvider'
import { IRestApiProject, Project } from '@/models/Project'
import { Device, IRestApiDevice, IDeviceVariables, IRestDeviceVariable } from '@/models/Device'
import { IRestApiProjectInvitation, ProjectInvitation } from '@/models/ProjectInvitation'
import HttpStatusCode from '@/providers/HttpStatusCode'
import { User } from '@/models/User'
import {
  CreateDevicePayload, UpsertMixedChartPayload, CreateProjectInvitationPayload, CreateProjectPayload, CreateReportPayload, UpdateDevicePayload,
  UpdateProjectPayload, UpdateReportPayload, UpsertReportSectionPayload, UserPayload, VariableEvaluationPayload, 
} from '@/providers/RestApiPayloads'
import { DeviceTunnelRequestStatus } from '@/models/Enums'
import { IRestApiProvider } from './IRestApiProvider'
import { Alarm, IRestApiAlarm } from '@/models/Alarm'
import { IRestApiProjectNotificationStatus, ProjectNotificationStatus } from '@/models/ProjectNotificationStatus'
import { Blackbox, IRestApiBlackbox } from '@/models/Blackbox'
import { Backup, IRestApiBackup } from '@/models/Backup'
import { IRestApiReport, Report, ReportSection } from '@/models/Report'
import { RestApiError } from './RestApiError'
import { IRestVariablesEvaluation } from '@/models/ReportEvaluator'
import { IRestApiMixedChart, MixedChart } from '@/models/MixedChart'
import { TimeRange } from '@/models/TimeRanges'

export default class RestApiProvider extends BaseRestApiProvider implements IRestApiProvider {
  constructor() {
    super()
    
    this.addAuthenticationMiddleware()
    this.addTimezoneHeaderMiddleware()
  }

  async getMe(): Promise<User> {
    try {
      const response = await this.axiosInstance.get('users')
      return new User(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    }
  }

  async updateMe(payload: UserPayload): Promise<User> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.put('users', payload)
      return new User(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async setTimezoneLastLogin(): Promise<void> {
    try {
      await this.axiosInstance.put(`users/timezone`, {
        timezone_last_login: this.timezone,
      })
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    }
  }

  async getAllTimezones(): Promise<string[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`timezones`)
      return response.data
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getAllProjects(): Promise<Project[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get('projects')
      return response.data.map((x: IRestApiProject) => new Project(x)).sort((a: Project, b: Project) => (a.name > b.name ? 1 : -1))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getProject(projectId: number): Promise<Project> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}`)
      return new Project(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async createProject(payload: CreateProjectPayload): Promise<Project> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiProject} = await this.axiosInstance.post('projects', payload)
      return new Project({
        ...data,
        owner: 'You',
        level: 0,
      })
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateProject(id: number, payload: UpdateProjectPayload): Promise<Project> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiProject} = await this.axiosInstance.put(`projects/${id}`, payload)
      return new Project(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async deleteProject(id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllProjectInvitations(projectId: number): Promise<ProjectInvitation[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/invitations`)
      return response.data.map((x: IRestApiProjectInvitation) => new ProjectInvitation(x)).sort((a: ProjectInvitation, b: ProjectInvitation) => (a.name > b.name ? 1 : -1))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async createProjectInvitation(projectId: number, payload: CreateProjectInvitationPayload): Promise<ProjectInvitation> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiProjectInvitation} = await this.axiosInstance.post(`projects/${projectId}/invitations`, payload)
      return new ProjectInvitation(data)
    } catch (e) {
      const err = BaseRestApiProvider.composeCustomError(e)
      if (err.responseErrorCode === HttpStatusCode.PRECONDITION_FAILED) {
        err.errorMessage = 'The email does not correspond to any user, please check the account is activated on Plexus Cloud'
      } else if (err.responseErrorCode === HttpStatusCode.NOT_ACCEPTABLE) {
        err.errorMessage = "You can't invite yourself to the project."
      } else if (err.responseErrorCode === HttpStatusCode.CONFLICT) {
        err.errorMessage = 'The user is already invited to the project. If you want to change his/her role, please delete the existing invitation to proceed.'
      }
      throw err
    } finally {
      this.setResponding(false)
    }
  }

  async deleteProjectInvitation(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/invitations/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllDevices(projectId: number): Promise<Device[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/devices`)
      return response.data.map((x: IRestApiDevice) => new Device(x)).sort((a: Device, b: Device) => (a.name > b.name ? 1 : -1))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async createDevice(projectId: number, payload: CreateDevicePayload): Promise<Device> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiDevice} = await this.axiosInstance.post(`projects/${projectId}/devices`, payload)
      return new Device(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateDevice(projectId: number, id: number, payload: UpdateDevicePayload): Promise<Device> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiDevice} = await this.axiosInstance.put(`projects/${projectId}/devices/${id}`, payload)
      return new Device(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateDeviceTunnelRequestStatus(projectId: number, id: number, value: DeviceTunnelRequestStatus): Promise<Device> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiDevice} = await this.axiosInstance.patch(`projects/${projectId}/devices/${id}/tunnel_request_status/${value}`)
      return new Device(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async deleteDevice(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/devices/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllAlarms(projectId: number): Promise<Alarm[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/alarms`)
      return response.data.map((x: IRestApiAlarm) => new Alarm(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async deleteAlarm(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/alarms/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async clearAllAlarms(projectId: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/alarms`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getNotifications(): Promise<ProjectNotificationStatus[]> {
    try {
      const response = await this.axiosInstance.get(`notifications`)
      return response.data.map((x: IRestApiProjectNotificationStatus) => new ProjectNotificationStatus(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
      // console.log('Failed to download notifications');
      // return [];
    }
  }

  async getNotificationBlacklistLevel(projectId: number): Promise<number> {
    this.setResponding(true)

    try {
      const result = await this.axiosInstance.get(`projects/${projectId}/notification_blacklists`);
      return result.data.blacklist_level;
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async setNotificationBlacklistLevel(projectId: number, level: number): Promise<void> {
    this.setResponding(true)

    try {
      if (level > 0) {
        await this.axiosInstance.post(`projects/${projectId}/notification_blacklists`, {
          blacklist_level: level
        });
      } else {
        await this.axiosInstance.delete(`projects/${projectId}/notification_blacklists`);
      }
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async setLastViewedNotification(projectId: number, notificationId: number): Promise<Project> {
    this.setResponding(true)

    try {
      const { data }: {data: IRestApiProject} = await this.axiosInstance.patch(`projects/${projectId}/last_viewed_notification/${notificationId}`)
      return new Project(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllBackups(projectId: number): Promise<Backup[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/backups`)
      return response.data.map((x: IRestApiBackup) => new Backup(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async downloadBackup(projectId: number, id: number): Promise<any> {
    return this.axiosInstance.get(`projects/${projectId}/backups/${id}`, { responseType: 'blob' })
  }

  async deleteBackup(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/backups/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async clearAllBackups(projectId: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/backups`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllBlackboxes(projectId: number): Promise<Blackbox[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/blackboxes`)
      return response.data.map((x: IRestApiBlackbox) => new Blackbox(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getAllMixedCharts(projectId: number): Promise<MixedChart[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/mixed_charts`)
      return response.data.map((x: IRestApiMixedChart) => new MixedChart(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getMixedChart(projectId: number, mixedChartId: number): Promise<MixedChart> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/mixed_charts/${mixedChartId}`)
      return new MixedChart(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getChartData(type: string, projectId: number, chartId: number, timeRange: TimeRange): Promise<any> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/${type}/${chartId}/data`, {
        params: {
          start: timeRange.start,
          end: timeRange.end,
        }
      })
      return response.data
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async createMixedChart(projectId: number, payload: UpsertMixedChartPayload): Promise<MixedChart> {
    this.setLoading(true)

    try {
      const modPayload = {
        name: payload.name,
        variables: Object.fromEntries(payload.variables)
      }
      const { data }: {data: IRestApiMixedChart} = await this.axiosInstance.post(`projects/${projectId}/mixed_charts`, modPayload)
      return new MixedChart(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async updateMixedChart(projectId: number, id: number, payload: UpsertMixedChartPayload): Promise<MixedChart> {
    this.setLoading(true)

    try {
      const modPayload = {
        name: payload.name,
        variables: Object.fromEntries(payload.variables)
      }
      const { data }: {data: IRestApiMixedChart} = await this.axiosInstance.put(`projects/${projectId}/mixed_charts/${id}`, modPayload)
      return new MixedChart(data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }
  
  async deleteMixedChart(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/mixed_charts/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getAllDeviceAndVariablesList(projectId: number): Promise<IDeviceVariables[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/device-and-variables`);
      return response.data.map((x: IRestDeviceVariable) => {
        return {
          deviceId: x.device_id,
          deviceName: x.device_name,
          deviceShortLabel: x.device_short_label,
          variables: x.variables
        } as IDeviceVariables
      })
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async getAllReports(projectId: number): Promise<Report[]> {
    this.setLoading(true)

    try {
      const response = await this.axiosInstance.get(`projects/${projectId}/reports`)
      return response.data.map((x: IRestApiReport) => new Report(x))
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setLoading(false)
    }
  }

  async createReport(projectId: number, payload: CreateReportPayload): Promise<Report> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.post(`projects/${projectId}/reports`, payload)
      return new Report(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateReport(projectId: number, id: number, payload: UpdateReportPayload): Promise<Report> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.put(`projects/${projectId}/reports/${id}`, payload)
      return new Report(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateReportStatus(projectId: number, id: number, status: string): Promise<Report> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.patch(`projects/${projectId}/reports/${id}/status/${status}`)
      return new Report(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async updateReportLastExecution(projectId: number, id: number): Promise<Report> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.patch(`projects/${projectId}/reports/${id}/exec`)
      return new Report(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }
  
  async deleteReport(projectId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/reports/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }

  async getReport(projectId: number, reportId: number): Promise<Report> {
    this.setLoading(true)

    const reports = await this.getAllReports(projectId)
    const report = reports.find((x: Report) => x.id === reportId)
    
    if (report == null) {
      throw {
        errorMessage: "Report not found",
        responseErrorCode: 404,
        responseExists: true,
      } as RestApiError
    }
    this.setLoading(false)
    return report;
  }

  async getDeviceAndVariablesList(projectId: number, deviceId: number): Promise<IDeviceVariables> {
    this.setLoading(true)

    const allDevicesVariablesList = await this.getAllDeviceAndVariablesList(projectId);
    const singleByDevice = allDevicesVariablesList.find((x: IDeviceVariables) => x.deviceId === deviceId);

    if (singleByDevice == null) {
      throw {
        errorMessage: "Device variables not found",
        responseErrorCode: 404,
        responseExists: false,
      } as RestApiError
    }
    
    this.setLoading(false)
    return singleByDevice
  }
  
  async upsertReportSection(projectId: number, reportId: number, payload: UpsertReportSectionPayload): Promise<ReportSection> {
    this.setResponding(true)

    try {
      const response = await this.axiosInstance.post(`projects/${projectId}/reports/${reportId}/sections`, payload)
      return new ReportSection(response.data)
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }
  
  async deleteReportSection(projectId: number, reportId: number, id: number): Promise<void> {
    this.setResponding(true)

    try {
      await this.axiosInstance.delete(`projects/${projectId}/reports/${reportId}/sections/${id}`)
      return
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }
  
  async evaluateVariables(projectId: number, payload: VariableEvaluationPayload): Promise<IRestVariablesEvaluation> {
    this.setResponding(true)

    try {
      const { data } = await this.axiosInstance.post(`projects/${projectId}/reports/evaluate-variables`, payload)
      return data as IRestVariablesEvaluation
    } catch (e) {
      throw BaseRestApiProvider.composeCustomError(e)
    } finally {
      this.setResponding(false)
    }
  }
}
