














































































































































































































































































































































import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import BaseProjectView from '@/views/BaseProjectView'
import { Device } from '@/models/Device'
import { RestApiError } from '@/providers/RestApiError'
import { DeviceTunnelRequestStatus } from '@/models/Enums'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Devices extends BaseProjectView {
  get tableFields(): any[] {
    const fields = [];

    if (["xs", "sm"].includes(this.appWindowStore.currentBreakPoint)) {
      fields.push({
        key: 'small-name',
        label: 'Name',
        sortByFormatted: true,
        sortable: true,
        formatter: (value: string, key: number, item: Device) =>
         `${item.name}<br/><span class="font-weight-lighter">${item.directLink != null ? '<a href="http://' + item.directLink + '/plexus" target="_blank">' + item.directLink + '</a>' : 'Direct link not set'}</span>`,
      });
    } else {
      if (this.devices.some(x => x.systemStatus !== 'running')) {
        fields.push({
          key: 'systemStatus',
          label: 'Status',
        });
      }
      fields.push({
        key: 'name',
        label: 'Name',
        sortable: true,
      });
      fields.push({
        key: 'directLink',
        label: 'Direct link',
        sortable: true,
        formatter: (value: string, key: number, item: Device) =>
         `<span>${item.directLink != null ? '<a href="http://' + item.directLink + '/plexus" target="_blank">' + item.directLink + '</a>' : 'Direct link not set'}</span>`,
      });
    }

    fields.push({
      key: 'tunnelActions',
      label: 'Tunnelling',
    });
    fields.push({
      key: 'actions',
      label: 'Actions',
    });

    return fields
  }

  get modalContent(): string {
    return `Are you sure you want remove the device "${this.selectedDevice?.name}" from the project "${this.project.name}"?`
  }

  devices: Device[] = [];
  selectedDevice: Device | null = null;

  formName: string | null = null;
  formShortLabel: string | null = null;
  formPlexusLabKey: string | null = null;
  formDirectLink: string | null = null;

  pollingInterval: any | null = null;
  canPoll = true;
  firstLoad = false;

  created(): void {
    super.onCreate()

    this.pollingInterval = setInterval(() => {
      if (this.devices.length > 0) this.fetchData(true);
    }, 15 * 1000);

    this.$watch(() => this.$route.params.projectId, () => this.fetchData(false))
  }

  beforeDestroy(): void {
    if (this.pollingInterval != null) {
      clearInterval(this.pollingInterval);
    }
  }

  async mounted(): Promise<void> {
    await this.fetchData()
  }

  async fetchData(fromPolling = false): Promise<void> {
    if (this.plexusStore.project == null) return;

    this.apiProvider.getAllDevices(this.plexusStore.project.id)
      .then((data: Device[]) => {
        if (fromPolling) {
          this.devices.forEach(x => {
            const receivedUpdateDevice = data.find(d => d.id === x.id);
            if (receivedUpdateDevice != null) {
              x.update(receivedUpdateDevice);
            }
          })
        } else {
          this.firstLoad = true
          this.devices = data
        }

        // this.devices.forEach(x => {
        //   x.updateTunnelWebsiteOnlineStatus()
        // })
        
        this.canPoll = true
      })
      .catch((error: RestApiError) => {
        this.canPoll = false
        this.handleRestApiException(error)
      })    
  }

  resetModal(): void {
    this.formName = null
    this.formShortLabel = null
    this.formPlexusLabKey = null
    this.formDirectLink = null
  }

  async handleCreate(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleCreateSubmit()
  }

  async handleUpdate(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleUpdateSubmit()
  }

  async handleCreateSubmit(): Promise<void> {
    const valid = await (this.$refs.createRules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.createDevice()
  }

  async handleUpdateSubmit(): Promise<void> {
    const valid = await (this.$refs.updateRules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.updateDevice()
  }

  async createDevice(): Promise<void> {
    this.apiProvider.createDevice(this.project.id, {
      name: this.formName as string,
      short_label: this.formShortLabel as string,
      plexus_lab_key: this.formPlexusLabKey as string,
      direct_link: this.formDirectLink as string,
    })
      .then((createdDevice: Device) => {
        this.devices.push(createdDevice)
        this.closeCreateDialog()
        this.makeToast('Device created successfully')
        this.plexusStore.initProject(this.project.id)
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  async updateDevice(): Promise<void> {
    if (this.selectedDevice == null) return;

    this.apiProvider.updateDevice(this.project.id, this.selectedDevice.id, {
      name: this.selectedDevice.name as string,
      short_label: this.selectedDevice.shortLabel as string,
      direct_link: (this.selectedDevice.directLink as string) == null || (this.selectedDevice.directLink as string).length === 0 ?
        null :
        this.selectedDevice.directLink as string,
    })
      .then((updatedDevice: Device) => {
        const index = this.devices.findIndex(x => x.id === updatedDevice.id)
        if (index > -1) {
          this.devices[index].update(updatedDevice);
        }
        this.closeUpdateDialog()
        this.makeToast('Device update successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  closeCreateDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-create-device')
    })
  }

  closeUpdateDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-update-device')
    })
  }

  openDeleteDialog(device: Device): void {
    this.selectedDevice = device.clone()
    this.$root.$bvModal.show('modal-delete-device')
  }

  async deleteDevice(): Promise<void> {
    if (this.selectedDevice == null) return

    const id = this.selectedDevice.id
    this.apiProvider.deleteDevice(this.project.id, id)
      .then(() => {
        const index = this.devices.findIndex(x => x.id === id)
        if (index > -1) this.devices.splice(index, 1)
        
        this.closeDeleteDialog()
        this.makeToast('Device removed successfully')
        this.plexusStore.initProject(this.project.id)
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  closeDeleteDialog(): void {
    this.selectedDevice = null
    this.$root.$bvModal.hide('modal-delete-device')
  }

  openInfoEditDialog(device: Device): void {
    this.selectedDevice = device.clone()
    this.$nextTick(() => {
      this.$root.$bvModal.show('modal-update-device')
    })
  }

  async toggleTunnelStatus(device: Device): Promise<void> {
    const statusToSet = device.tunnelRequestStatus === DeviceTunnelRequestStatus.Off ? DeviceTunnelRequestStatus.On : DeviceTunnelRequestStatus.Off;
    this.apiProvider.updateDeviceTunnelRequestStatus(this.project.id, device.id, statusToSet)
      .then((updatedDevice: Device) => {
        const index = this.devices.findIndex(x => x.id === updatedDevice.id)
        if (index > -1) {
          this.devices[index].tunnelRequestStatus = updatedDevice.tunnelRequestStatus
        }
        
        this.makeToast('Tunnel status updated successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  openTunnelWebsite(tunnelLink: string): void {
    window.open(tunnelLink, '_blank', undefined)?.focus();
  }

  openWaitingDialog(device: Device): void {
    this.makeWarningToast(`Please wait, the device "${device.name}" will be connected soon. Contact us if nothing changes within 1-2 minutes.`)
  }

  openTunnelNonRespondingDialog(device: Device): void {
    this.makeWarningToast(`A tunnel for device "${device.name}" is opened, but the device is unresponsive. Check "External access" is active on your PlexusLAB.`)
  }
}
