









































































































































import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import BaseProjectView from '@/views/BaseProjectView'
import { ProjectInvitation } from '@/models/ProjectInvitation'
import { RestApiError } from '@/providers/RestApiError'
import { UserLevel } from '@/models/Enums'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Users extends BaseProjectView {
  get tableFields(): any[] {
    const fields = [];

    if (["xs", "sm"].includes(this.appWindowStore.currentBreakPoint)) {
      fields.push({
        key: 'name',
        sortable: true,
        formatter: (value: string, key: number, item: ProjectInvitation) =>
         `${item.name}<br/><span class="font-weight-lighter">${item.email}</span><br/><span class="font-weight-bolder">${item.levelString}</span>`,
      });
    } else {
      fields.push({
        key: 'name',
        sortable: true,
      });
      fields.push({
        key: 'email',
        sortable: true,
      });
      fields.push({
        key: 'levelString',
        label: 'Level',
        sortable: true,
      });
    }

    if (this.amiOwner) {
      fields.push({
        key: 'actions',
        label: 'Actions',
      });
    }

    return fields
  }

  projectInvitations: ProjectInvitation[] = [];
  projectInvitationToDelete: ProjectInvitation | null = null;

  levels = [
    { value: UserLevel.Operator, text: 'Operator' },
    { value: UserLevel.Supervisor, text: 'Supervisor' },
    { value: UserLevel.Admin, text: 'Admin' },
  ];
  formEmail: string | null = null;
  formLevel = UserLevel.Operator;

  get modalContent(): string {
    return `Are you sure you want remove "${this.projectInvitationToDelete?.name}" from the project "${this.project.name}"?`
  }

  created(): void {
    super.onCreate()
  }

  async mounted(): Promise<void> {
    await this.fetchData()
  }

  async fetchData(): Promise<void> {
    if (this.project == null || !this.amiSupervisor) return;

    this.apiProvider.getAllProjectInvitations(this.project.id)
      .then((data: ProjectInvitation[]) => {
        this.projectInvitations = data.map(x => {
          // console.log(this.$store.state.plexus.user);
          
          return new ProjectInvitation({
            id: x.id,
            user_id: x.userId,
            email: x.email,
            level: x.level,
            name: this.$store.state.plexus.user.id === x.userId ? "You" : x.name,
          });
        })
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  resetModal(): void {
    this.formEmail = null
    this.formLevel = UserLevel.Operator
  }

  async handleOk(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleSubmit()
  }

  async handleSubmit(): Promise<void> {
    const valid = await (this.$refs.rules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.createProjectInvitation()
  }

  async createProjectInvitation(): Promise<void> {
    this.apiProvider.createProjectInvitation(this.project.id, {
      invited_user_email: this.formEmail as string,
      level: this.formLevel,
    })
      .then((createdProjectInvitation: ProjectInvitation) => {
        this.projectInvitations.push(createdProjectInvitation)
        this.closeCreateDialog()
        this.makeToast('User invited successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error)) 
  }

  closeCreateDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-create-user')
    })
  }

  openDeleteDialog(projectInvitation: ProjectInvitation): void {
    this.$root.$bvModal.show('modal-delete-user')
    this.projectInvitationToDelete = projectInvitation
  }

  async deleteProjectInvitation(): Promise<void> {
    if (this.projectInvitationToDelete == null) return

    const id = this.projectInvitationToDelete.id;
    this.apiProvider.deleteProjectInvitation(this.project.id, id)
      .then(() => {
        const index = this.projectInvitations.findIndex(x => x.id === id)
        if (index > -1) this.projectInvitations.splice(index, 1)
        
        this.closeDeleteDialog()
        this.makeToast('User invitation removed successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  closeDeleteDialog(): void {
    this.projectInvitationToDelete = null
    this.$root.$bvModal.hide('modal-delete-user')
  }
}
