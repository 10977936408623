import '@/registerServiceWorker';

import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import store from '@/store'
import '@/store/modules/Plexus'
import '@/store/modules/Notificator'
import router from '@/router'

import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
// import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import { getModule } from 'vuex-module-decorators';
import Plexus from '@/store/modules/Plexus';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.productionTip = false; // dynamic module, because of action that needs to call mutation directly (async ops)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas);

const plexusStore = getModule(Plexus, store)
plexusStore.initAuthProvider()
plexusStore.initRestApiProvider()
plexusStore.login()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
