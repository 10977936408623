import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import FlexSpinner from '@/components/FlexSpinner.vue'
import SmallSpinner from '@/components/SmallSpinner.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('flex-spinner', FlexSpinner)
Vue.component('small-spinner', SmallSpinner)

// https://vee-validate.logaretm.com/v3/guide/rules.html#installing-all-rules
import { extend } from 'vee-validate';
import { max, min, required, email, regex, alpha_num as alphaNum } from 'vee-validate/dist/rules';

extend('max', {
  ...max,
  message: 'Input is too long'
});
extend('min', {
  ...min,
  message: 'Input is too short'
});
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', email);
extend('alpha_num', {
  ...alphaNum,
  message: 'Invalid char'
});
extend('regex', regex);

import {
  BCard, BCardBody, BLink, BCardText, BCardGroup, BCardTitle, BCardHeader, BCardFooter,
  BButton, BModal, BContainer, BFormInput, BFormGroup, BFormRadioGroup, BInputGroupPrepend,
  BRow, BCol, BInputGroup, BInputGroupAppend, BBadge,
  BDropdown, BDropdownItem, BDropdownDivider, BDropdownGroup, BDropdownHeader,
  BTable, BForm, BFormSelect, BFormRadio, BFormTags, BFormTag, BImg, BImgLazy,
} from 'bootstrap-vue'

Vue.component('b-card', BCard)
Vue.component('b-card-body', BCardBody)
Vue.component('b-card-group', BCardGroup)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-footer', BCardFooter)
Vue.component('b-link', BLink)
Vue.component('b-button', BButton)
Vue.component('b-modal', BModal)
Vue.component('b-container', BContainer)
Vue.component('b-form', BForm)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-radio', BFormRadio)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-tag', BFormTag)
Vue.component('b-form-tags', BFormTags)
Vue.component('b-row', BRow)
Vue.component('b-col', BCol)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)
Vue.component('b-badge', BBadge)
Vue.component('b-table', BTable)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-dropdown-divider', BDropdownDivider)
Vue.component('b-dropdown-group', BDropdownGroup)
Vue.component('b-dropdown-header', BDropdownHeader)
Vue.component('b-img', BImg)
Vue.component('b-img-lazy', BImgLazy)
