/* eslint-disable camelcase */

import { IChart } from "./Chart";

export interface IRestApiBlackbox {
  id: number;
  bb_id: string;
  device_id: number;
  device_name: string;
  name: string;
  variables: any[];
  active: number;
  first_data_record_at: string;       // ISO string
  last_data_record_at: string;       // ISO string
  sql_fault: string;
}

export class Blackbox implements IChart {
  id: number;
  bbId: string;
  deviceId: number;
  deviceName: string;
  name: string;
  variables: string[];
  active: boolean;
  firstDataRecordAt?: Date;
  lastDataRecordAt?: Date;
  sqlFault: string;

  get disabled(): boolean {
    return this.active === false || this.sqlFault != null /*|| !this.hasData*/
  }

  get hasData(): boolean {
    return this.firstDataRecordAt != null && this.lastDataRecordAt != null
  }

  constructor(data: IRestApiBlackbox) {
    this.id = data.id
    this.bbId = data.bb_id
    this.deviceId = data.device_id
    this.deviceName = data.device_name
    this.name = data.name
    this.variables = data.variables.map(x => x.key)
    this.active = data.active === 1
    this.firstDataRecordAt = data.first_data_record_at != null ? new Date(data.first_data_record_at) : undefined
    this.lastDataRecordAt = data.last_data_record_at != null ? new Date(data.last_data_record_at) : undefined
    this.sqlFault = data.sql_fault
  }
}
