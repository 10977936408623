







import BaseComponent from '@/components/BaseComponent'
import Component from 'vue-class-component'
import { BSpinner } from 'bootstrap-vue'

@Component({
  components: {
    BSpinner
  },
})
export default class FlexSpinner extends BaseComponent {
  get isLoading(): boolean {
    return this.plexusStore.isLoading
  }
}
