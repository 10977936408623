





























































import BaseComponent from '@/components/BaseComponent'
import Component from 'vue-class-component'

import { Prop } from 'vue-property-decorator'
import { Project } from '@/models/Project'

@Component
export default class ProjectCard extends BaseComponent {
  @Prop() readonly project!: Project;

  selectProject(): void {
    this.plexusStore.SELECT_PROJECT(this.project)
  }

  get alarmCountString(): string {
    return `${this.project.unreadAlarmCount} new alarm${(this.project.unreadAlarmCount === 1 ? '' : 's')}`
  }

  goTo(to: string): void {
    this.selectProject()

    switch (to) {
      case 'dashboard':
        this.$router.push(`project/${this.project.id}/dashboard`)
        break
      case 'devices':
        this.$router.push(`project/${this.project.id}/devices`)
        break
      case 'alarms':
        this.$router.push(`project/${this.project.id}/alarms`)
        break
      case 'blackboxes':
        this.$router.push(`project/${this.project.id}/blackboxes`)
        break
      case 'users':
        this.$router.push(`project/${this.project.id}/users`)
        break
      default: break
    }
  }
}
