import AppConfig from '@/store/modules/AppConfig';
import AppWindow from '@/store/modules/AppWindow';
import Notificator from '@/store/modules/Notificator';
import Plexus from '@/store/modules/Plexus';
import VerticalMenu from '@/store/modules/VerticalMenu';
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators';


export type ElementOffset = {
  left: number;
  top: number
};

export default class BaseComponent extends Vue {
  get appWindowStore(): AppWindow {
    return getModule(AppWindow, this.$store);
  }

  get appConfigStore(): AppConfig {
    return getModule(AppConfig, this.$store);
  }

  get plexusStore(): Plexus {
    return getModule(Plexus, this.$store);
  }

  get notificatorStore(): Notificator {
    return getModule(Notificator, this.$store);
  }

  get verticalMenuStore(): VerticalMenu {
    return getModule(VerticalMenu, this.$store);
  }

  get isMobile(): boolean {
    return this.appWindowStore.isMobile
  }

  dateToLocaleStringWithUserTimezone(date: Date): string {
    if (this.plexusStore.user == null) return date.toLocaleString()

    const area = this.plexusStore.user.timezone.split('/')[0]

    switch (area) {
      case "America":
        return date.toLocaleString('en-US', { timeZone: this.plexusStore.user.timezone })
      case "Europe":
        return date.toLocaleString('de-DE', { timeZone: this.plexusStore.user.timezone })
      default:
        return date.toLocaleString('en-US', { timeZone: this.plexusStore.user.timezone })
    }
  }

  getElementOffset(el: Element | null): ElementOffset {
    const rect = el?.getBoundingClientRect();
  
    return {
      left: (rect?.left || 0) + window?.scrollX,
      top: (rect?.top || 0) + window?.scrollY,
    }
  }
}
