import {
  Module, VuexModule, Mutation, Action, 
} from 'vuex-module-decorators'

import store from '@/store'

import { User } from '@/models/User'
import { AuthProvider, AuthSuccessResponse, AuthTokens } from '@/providers/AuthProvider'
import { Project } from '@/models/Project'
import { IRestApiProvider } from '@/providers/IRestApiProvider'
import initProvider from '@/providers/RestApiProviderFactory'

@Module({
  name: 'plexus',
  namespaced: true,
  dynamic: true,
  store,
})
export default class Plexus extends VuexModule {
  authProvider: AuthProvider | null = null
  restApiProvider: IRestApiProvider | null = null
  user: User | null = localStorage.getItem('plexus-cloud-user') != null ? new User(JSON.parse((localStorage.getItem('plexus-cloud-user') as string))) : null
  project: Project | null = null

  get isLogged(): boolean {
    return this.user != null
  }

  get isLoading(): boolean {
    return this.restApiProvider == null ? false : this.restApiProvider.isLoading
  }

  get isResponding(): boolean {
    return this.restApiProvider == null ? false : this.restApiProvider.isResponding
  }

  get isProjectSelected(): boolean {
    return this.project != null
  }

  get generateAccountPage(): string {
    return this.authProvider != null ? this.authProvider.createAccountUrl() + '/personal-info' : ''
  }

  @Action
  initAuthProvider(): void {
    // console.log("init authProvider");
    if (this.authProvider != null) return
    
    this.SET_AUTH_PROVIDER(new AuthProvider({
      onAuthRefreshError: () => {
        this.SET_USER(null)
      },
      onAuthRefreshSuccess: (tokens: AuthTokens) => {
        if (this.restApiProvider != null) {
          this.restApiProvider.acquireAuthToken(tokens.token)
          localStorage.setItem('plexus-cloud-auth-tokens', JSON.stringify(tokens))
        }
      },
      onAuthSuccess: async (result: AuthSuccessResponse) => {
        if (this.authProvider == null || this.restApiProvider == null) return

        try {
          this.restApiProvider.acquireAuthToken(result.authTokens.token)

          await this.restApiProvider.setTimezoneLastLogin()
          const user = await this.restApiProvider.getMe()
          this.SET_USER(user)

          localStorage.setItem('plexus-cloud-auth-tokens', JSON.stringify(result.authTokens))
          localStorage.setItem('plexus-cloud-auth-time-skew', JSON.stringify(result.timeSkew))
        } catch (error: any) {
          if (error != null) console.log(error.message)

          this.SET_USER(null)
        }
      },
    }))
  }

  @Action
  initRestApiProvider(): void {
    const restApiProvider = initProvider();
    this.SET_REST_API_PROVIDER(restApiProvider)
    const storedAuthTokens = JSON.parse(localStorage.getItem('plexus-cloud-auth-tokens') || '{}')
    restApiProvider.acquireAuthToken(storedAuthTokens.token)
  }

  @Action
  async login(): Promise<void> {
    if (this.authProvider == null || this.restApiProvider == null) return

    try {
      // console.log('login');        
      let storedAuthTokens = null
      let storedTimeSkew = 1
      if (localStorage.getItem('plexus-cloud-auth-tokens') != null) {
        storedAuthTokens = JSON.parse(localStorage.getItem('plexus-cloud-auth-tokens') || '{}')
        storedTimeSkew = Number.parseInt(localStorage.getItem('plexus-cloud-auth-time-skew') || "1")
        this.restApiProvider.acquireAuthToken(storedAuthTokens.token)
      }

      await this.authProvider.login({
        authTokens: storedAuthTokens,
        timeSkew: storedTimeSkew,
      })
    } catch (error: any) {
      if (error != null) console.log(error.message)
      
      this.SET_USER(null)
    }
  }
  
  @Action
  async logout(): Promise<void> {
    if (this.authProvider == null) return

    try {
      localStorage.clear()
      this.SET_USER(null)
      
      await this.authProvider.logout()
    } catch (error: any) {
      if (error != null) console.log(error.message)
      
      localStorage.clear()
      this.SET_USER(null)
    }
  }

  @Action({ rawError: true })
  async initProject(projectId: number): Promise<void> {
    if (this.restApiProvider == null) return
    
    const project = await this.restApiProvider.getProject(projectId)
    this.SELECT_PROJECT(project)
  }

  @Mutation
  SET_AUTH_PROVIDER(value: AuthProvider): void {
    this.authProvider = value
  }

  @Mutation
  SET_REST_API_PROVIDER(value: IRestApiProvider): void {
    this.restApiProvider = value
  }

  @Mutation
  SET_USER(user: User | null): void {
    if (user != null) {
      localStorage.setItem('plexus-cloud-user', JSON.stringify(user))
    } else {
      localStorage.removeItem('plexus-cloud-user')
      localStorage.removeItem('plexus-cloud-auth-tokens')
      localStorage.removeItem('plexus-cloud-auth-time-skew')
    }

    this.user = user
  }

  @Mutation
  SELECT_PROJECT(value: Project): void {
    this.project = value
  }

  @Mutation
  CLEAR_PROJECT(): void {
    this.project = null
  }
}
