/* eslint-disable camelcase */

export interface IRestApiProjectNotificationStatus {
  project_id: number;
  project_name: string;
  user_name: string;
  device_name: string;
  alarm_count: number;
  message_count: number;
}

export class ProjectNotificationStatus {
  projectId: number;
  projectName: string;
  userName: string;
  deviceName: string;
  alarmCount: number;
  messageCount: number;

  get totalCount(): number {
    return this.alarmCount + this.messageCount
  }

  get totalCountString(): string {
    return (this.alarmCount + this.messageCount) > 9 ? "9+" : (this.alarmCount + this.messageCount).toString()
  }

  get messagesCountString(): string {
    return this.messageCount > 9 ? "9+" : this.messageCount.toString()
  }

  get alarmsCountString(): string {
    return this.alarmCount > 9 ? "9+" : this.alarmCount.toString()
  }

  constructor(data: IRestApiProjectNotificationStatus) {
    this.projectId = data.project_id
    this.projectName = data.project_name
    this.userName = data.user_name
    this.deviceName = data.device_name
    this.alarmCount = data.alarm_count
    this.messageCount = data.message_count
  }
}
