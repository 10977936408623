/* eslint-disable camelcase */
import { UserLevel } from '@/models/Enums';

export interface IRestApiProject {
  id: number;
  uuid: string;
  name: string;
  full_address: string;
  created_at: string;               // ISO string
  owner: string;
  level: number;
  last_viewed_notification_id: number | null;
  non_running_devices_count: number;
  all_devices_count: number;
}

export class Project {
  id: number;
  uuid: string;
  name: string;
  fullAddress: string;
  creationDate: Date;
  owner: string;
  level: number;
  lastViewedNotificationId: number | null;
  nonRunningDevicesCount: number;
  allDevicesCount: number;

  get isMine(): boolean {
    return this.level === UserLevel.Owner;
  }
  
  get amiOwner(): boolean {
    return this.level === 0;
  }
  
  get amiAdmin(): boolean {
    return this.level <= 10;
  }
  
  get amiSupervisor(): boolean {
    return this.level <= 20;
  }
  
  get amiOperator(): boolean {
    return this.level <= 40;
  }

  get levelString(): string {
    return UserLevel[this.level]
  }

  constructor(data: IRestApiProject) {
    this.id = data.id
    this.uuid = data.uuid
    this.name = data.name
    this.fullAddress = data.full_address
    this.creationDate = new Date(data.created_at)
    this.owner = data.owner
    this.level = data.level
    this.lastViewedNotificationId = data.last_viewed_notification_id
    this.nonRunningDevicesCount = data.non_running_devices_count
    this.allDevicesCount = data.all_devices_count

    this._unreadAlarmCount = null
    this._unreadMessageCount = null
  }

  private _unreadAlarmCount: number | null;
  set unreadAlarmCount(value: number | null) {
    this._unreadAlarmCount = value
  }
  get unreadAlarmCount(): number | null {
    return this._unreadAlarmCount
  }

  private _unreadMessageCount: number | null;
  set unreadMessageCount(value: number | null) {
    this._unreadMessageCount = value
  }
  get unreadMessageCount(): number | null {
    return this._unreadMessageCount
  }

  get notificationsCount(): number | null {
    return (this._unreadAlarmCount != null && this._unreadMessageCount != null) ? this._unreadAlarmCount + this._unreadMessageCount : null
  }
}
