

































































































































import Component from 'vue-class-component'
import lodash from 'lodash'
import { getModule } from 'vuex-module-decorators'
import Notificator from '@/store/modules/Notificator'

import BaseProjectView from '@/views/BaseProjectView'
import { Alarm } from '@/models/Alarm'
import { RestApiError } from '@/providers/RestApiError'

@Component
export default class Devices extends BaseProjectView {
  get tableFields(): any[] {
    const fields = [];

    fields.push({
      key: 'is-new',
      label: '',
      thClass: 'is-new',
      tdClass: 'is-new',
      formatter: (value: string, key: number, item: Alarm) => item.isNew ? '•' : '',
    });

    if (["xs", "sm"].includes(this.appWindowStore.currentBreakPoint)) {
      fields.push({
        key: 'small-name',
        label: 'Alert',
        sortByFormatted: true,
        sortable: true,
        formatter: (value: string, key: number, item: Alarm) =>
         `${item.name}<br/><span class="font-weight-lighter font-size-smaller">${item.description}</span>`,
      });
      fields.push({
        key: 'small-info',
        label: 'Info',
        sortByFormatted: true,
        sortable: true,
        formatter: (value: string, key: number, item: Alarm) =>
         `${item.deviceName}<br/><span class="font-weight-lighter font-size-smaller">${this.dateToLocaleStringWithUserTimezone(item.triggeredAt)}</span>`,
      });
    } else {
      fields.push({
        key: 'name',
        label: 'Alert',
        sortable: true,
        formatter: (value: string, key: number, item: Alarm) =>
         `${item.type === 'alarm' ? '🔴' : '🟡'}&emsp;&emsp;${value}`,
      });
      fields.push({
        key: 'description',
        label: 'Description',
        sortable: true,
      });
      fields.push({
        key: 'deviceName',
        label: 'Device',
        sortable: true,
      });
      fields.push({
        key: 'triggeredAt',
        label: 'Date / Time',
        formatter: (value: Date) => this.dateToLocaleStringWithUserTimezone(value),
      });
    }

    fields.push({
      key: 'actions',
      label: 'Actions',
    });

    return fields
  }

  get modalContent(): string {
    return `Are you sure you want clear all the alarms for the project "${this.project.name}"?`
  }

  get blacklistDropdownText(): string {
    return this.notificationBlacklistLevel === 0 ?
      'Receiving&nbsp;&nbsp;🔴&nbsp;🟡' :
      this.notificationBlacklistLevel === 30 ?
        "Receiving&nbsp;&nbsp;🔴" :
        "Not notified"
  }

  get blacklistDropdownColor(): string {
    return this.notificationBlacklistLevel === 0 ?
      "success" :
      this.notificationBlacklistLevel === 30 ?
        "success" :
        "warning"
  }

  filter: string | null = null;
  alarms: Alarm[] = [];
  lastAlarmId = 0;
  devices: any[] = [];
  clearNewNotificationsTimeout: any = null;
  notificationBlacklistLevel = 0;

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.initPage)
  }

  async mounted(): Promise<void> {
    await this.initPage()
  }

  beforeDestroy(): void {
    if (this.clearNewNotificationsTimeout != null) {
      clearTimeout(this.clearNewNotificationsTimeout)
    }
  }

  async initPage(): Promise<void> {
    await this.fetchData()
    
    if (this.alarms.length > 0) {
      this.setLastViewedNotificationIfNeeded()
    }
  }

  async filterByDevice(deviceId: number | null): Promise<void> {
    await this.fetchData()

    if (deviceId != null) {
      this.alarms = this.alarms.filter((x: Alarm) => {
        return x.deviceId == deviceId
      })
    }
  }
  
  setLastViewedNotificationIfNeeded(): void {
    this.clearNewNotificationsTimeout = setTimeout(async () => {
      if (this.plexusStore.restApiProvider == null || this.alarms == null || this.alarms.length == 0) return;

      const maxAlertId = this.alarms[0].id
      const lastNotificationViewed = this.project.lastViewedNotificationId || 0
      if (lastNotificationViewed >= maxAlertId) return;

      this.clearNewNotificationsTimeout = null
      const updatedProject = await this.plexusStore.restApiProvider.setLastViewedNotification(this.project.id, maxAlertId)
      this.plexusStore.SELECT_PROJECT(updatedProject)
      this.plexusStore.restApiProvider.getNotifications()
        .then(notifications => {
          this.notificatorStore.STORE_NOTIFICATION_STATUS(notifications)
        })
        .catch(error => this.handleRestApiException(error))
    }, 5 * 1000);
  }
  
  async fetchData(): Promise<void> {
    const promises: Promise<any>[] = [
      this.apiProvider.getAllAlarms(this.project.id),
      this.apiProvider.getNotificationBlacklistLevel(this.project.id)
    ];
    return Promise.all(promises)
      .then((data: any) => {
        this.alarms = (data[0] as Alarm[])
        this.lastAlarmId = lodash.maxBy(this.alarms, 'id')?.id || 0; 
        this.notificationBlacklistLevel = (data[1] as number)
        this.devices = lodash.uniqBy(this.alarms.map((x: Alarm) => {
          return {
            name: x.deviceName,
            id: x.deviceId,
          }
        }), 'id')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  async deleteAlarm(alarm: Alarm): Promise<void> {
    return this.apiProvider.deleteAlarm(this.project.id, alarm.id)
      .then(() => {
        const index = this.alarms.findIndex(x => x.id === alarm.id)
        if (index > -1) this.alarms.splice(index, 1)
        
        this.makeToast('Alarm cleared successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  async clearAlarms(): Promise<void> {
    return this.apiProvider.clearAllAlarms(this.project.id)
      .then(async () => {
        this.alarms.splice(0, this.alarms.length)
        this.makeToast('All alarms cleared successfully')
        if (this.lastAlarmId > 0) {
          await this.apiProvider.setLastViewedNotification(this.project.id, this.lastAlarmId)
          getModule(Notificator).poll()
        }
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  async setNotificationBlacklistLevel(silenceUpToValue: number): Promise<void> {
    this.apiProvider.setNotificationBlacklistLevel(this.project.id, silenceUpToValue)
      .then(() => {
        this.notificationBlacklistLevel = silenceUpToValue
        this.makeToast('Notification settings saved')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }
}
