/* eslint-disable @typescript-eslint/no-explicit-any */
import * as env from 'env-var'
import Keycloak, {
  KeycloakConfig, KeycloakError, KeycloakInitOptions, KeycloakProfile, 
} from 'keycloak-js'

export interface KeycloakUser {
  readonly profileType: string;
  readonly username: string;
  readonly email: string;
  readonly name: string;
  readonly givenName: string;
  readonly familyName: string;
}

export interface AuthTokens {
  idToken?: string;
  refreshToken?: string;
  token?: string;
}

export interface AuthSuccessResponse {
  message: string,
  timeSkew: number,
  authTokens: AuthTokens
}

export interface AuthProviderParams {
  onAuthSuccess?(result: AuthSuccessResponse): void;
  onTokenExpired?(): void;
  onAuthRefreshError?(): void;
  onAuthRefreshSuccess?(tokens: AuthTokens): void;
}

export class AuthProvider {
  keycloakInstance: Keycloak.KeycloakInstance;

  // callbacks for Vuex store which has an instance of AuthProvider
  private onAuthSuccess: (result: AuthSuccessResponse) => void;
  private onTokenExpired: () => void;
  private onAuthRefreshError: () => void;
  private onAuthRefreshSuccess: (tokens: AuthTokens) => void;

  static now(): string {
    return new Date(Date.now()).toLocaleTimeString()
  }

  constructor(params: AuthProviderParams) {
    this.onAuthRefreshError = params.onAuthRefreshError || function () { /* noop */ }
    this.onAuthRefreshSuccess = params.onAuthRefreshSuccess || function () { /* noop */ }
    this.onTokenExpired = params.onTokenExpired || function () { /* noop */ }
    this.onAuthSuccess = params.onAuthSuccess || function () { /* noop */ }

    const initOptions: KeycloakConfig = {
      clientId: env.get('VUE_APP_CLIENT_ID').required().asString(),
      url: env.get('VUE_APP_KEYCLOAK_URL').required().asUrlString(),
      realm: env.get('VUE_APP_KEYCLOAK_REALM_NAME').required().asString(),
    }

    this.keycloakInstance = Keycloak(initOptions)

    this.keycloakInstance.onAuthError = ((error: KeycloakError) => {
      console.warn(`${AuthProvider.now()} Keycloak => onAuthError`, error)
    })
    this.keycloakInstance.onAuthLogout = (() => {
      // console.warn(`${AuthProvider.now()} Keycloak => onAuthLogout`)
    })
    this.keycloakInstance.onAuthRefreshError = (() => {
      console.warn(`${AuthProvider.now()} Keycloak => onAuthRefreshError`)
      this.onAuthRefreshError()
    })
    this.keycloakInstance.onAuthRefreshSuccess = (() => {
      // console.warn(AuthProvider.now() + " Keycloak => onAuthRefreshSuccess. Token: ", this.keycloakInstance.token);
      // console.warn(`${AuthProvider.now()} Keycloak => onAuthRefreshSuccess`)
      this.onAuthRefreshSuccess({
        idToken: this.keycloakInstance.idToken,
        refreshToken: this.keycloakInstance.refreshToken,
        token: this.keycloakInstance.token,
      })
    })
    this.keycloakInstance.onAuthSuccess = (() => {
      this.onAuthSuccess({
        authTokens: {
          idToken: this.keycloakInstance.idToken,
          refreshToken: this.keycloakInstance.refreshToken,
          token: this.keycloakInstance.token,
        },
        timeSkew: this.keycloakInstance.timeSkew != null ? this.keycloakInstance.timeSkew : 1,
        message: 'Authentication success',
      })
    })
    this.keycloakInstance.onTokenExpired = (() => {
      // console.warn(AuthProvider.now() + " Keycloak => onTokenExpired");
      this.keycloakInstance.updateToken(30)
        .catch(() => {
          console.error('Failed to refresh the token, or the session has expired')
        })
      this.onTokenExpired()
    })
  }

  async getUserInfo(): Promise<KeycloakUser> {
    return new Promise((resolve, reject) => {
      if (this.keycloakInstance.idToken != null && this.keycloakInstance.idTokenParsed != null) {
        // console.log("Keycloak => idTokenParsed", this.keycloakInstance.idTokenParsed);
        resolve({
          profileType: 'IDToken',
          username: (this.keycloakInstance.idTokenParsed as any).preferred_username,
          email: (this.keycloakInstance.idTokenParsed as any).email,
          name: (this.keycloakInstance.idTokenParsed as any).name,
          givenName: (this.keycloakInstance.idTokenParsed as any).given_name,
          familyName: (this.keycloakInstance.idTokenParsed as any).family_name,
        })
      } else {
        this.keycloakInstance.loadUserProfile()
          .then((profile: KeycloakProfile) => {
            // console.log("Keycloak => loadUserProfile", profile);
            resolve({
              profileType: 'IDToken',
              username: profile.username || '',
              email: profile.email || '',
              name: `${profile.firstName} ${profile.lastName}`,
              givenName: profile.firstName || '',
              familyName: profile.lastName || '',
            })
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
    })
  }

  async login({
    authTokens,
    timeSkew,
  }: {
    authTokens?: AuthTokens,
    timeSkew: number,
  }): Promise<AuthSuccessResponse> {
    let initParams = {
      enableLogging: process.env.NODE_ENV !== 'production',
      onLoad: 'login-required',
      redirectUri: `${env.get('VUE_APP_BASE_URL').required().asString()}logged?`
    } as KeycloakInitOptions

    if (authTokens != null) {
      initParams = {
        ...initParams,
        ...authTokens,
        timeSkew,
      }
    }

    return new Promise((resolve, reject) => {
      // console.log('Initing Keycloak with initParams', initParams);
      
      this.keycloakInstance.init(initParams)
        .then(isAuth => {
          // console.warn("init => isAuth", isAuth);
          if (!isAuth) {
            reject(new Error('No auth'))
          } else {
            resolve({
              message: 'Auth',
              timeSkew: this.keycloakInstance.timeSkew != null ? this.keycloakInstance.timeSkew : 1,
              authTokens: {
                idToken: this.keycloakInstance.idToken,
                refreshToken: this.keycloakInstance.refreshToken,
                token: this.keycloakInstance.token,
              },
            })
          }
        })
        .catch(e => {
          console.error(e)
          reject(e)
        })
    })
  }

  async logout(): Promise<void> {
    await this.keycloakInstance.clearToken()
    
    await this.keycloakInstance.logout({
      redirectUri: `${env.get('VUE_APP_BASE_URL').required().asString()}error-401` 
    })
  }

  createAccountUrl(): string {
    return this.keycloakInstance.createAccountUrl()
  }
}
