
















































































































import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import lodash from 'lodash'

import BaseView from '@/views/BaseView'
import ProjectCard from '@/components/ProjectCard.vue'
import FakeBox from '@/components/FakeBox.vue'
import { Project } from '@/models/Project'
import { RestApiError } from '@/providers/RestApiError'
import { ProjectNotificationStatus } from '@/models/ProjectNotificationStatus'

@Component({
  components: {
    ProjectCard,
    FakeBox,

    ValidationProvider,
    ValidationObserver,
  },
})
export default class Projects extends BaseView {
  projects: Project[] = [];
  showCreateDialog = false;
  projectToDelete: Project | null = null;
  formName: string | null = null;
  formFullAddress: string | null = null;

  get groupedProjects(): Project[][] {
    if (this.projects.length === 0) return []

    const currentBreakpoint = this.appWindowStore.currentBreakPoint
    let numberOfCardsOnSingleRow = 1
    if (currentBreakpoint === 'md') {
      numberOfCardsOnSingleRow = 2;
    } else if (['lg'].includes(currentBreakpoint)) {
      numberOfCardsOnSingleRow = 3;
    } else if (['xl'].includes(currentBreakpoint)) {
      numberOfCardsOnSingleRow = 4;
    }

    const chunks: Project[][] = lodash.chunk(this.projects, numberOfCardsOnSingleRow)

    if (chunks[chunks.length - 1].length !== numberOfCardsOnSingleRow) {
      const holes = numberOfCardsOnSingleRow - chunks[chunks.length - 1].length
      for (let i = 0; i < holes; i += 1) {
        chunks[chunks.length - 1].push(new Project({
          id: 0, name: '', full_address: '', owner: '', level: 0, uuid: '', last_viewed_notification_id: null, created_at: "1970-01-01T00:00:00", non_running_devices_count: 0, all_devices_count: 0
        }))
      }
    }
    
    return chunks
  }

  created(): void {
    this.appConfigStore.UPDATE_NAV_MENU_HIDDEN(true)
    this.plexusStore.CLEAR_PROJECT()
    this.$watch(() => this.plexusStore.isLogged, this.onLoggedStateChanged)
    this.$watch(() => this.notificatorStore.notifications, this.onNotificatorStoreNotificationsUpdate)
  }

  async mounted(): Promise<void> {
    if (this.isLogged) {
      await this.fetchData()
    }
  }

  destroyed(): void {
    this.appConfigStore.UPDATE_NAV_MENU_HIDDEN(false)
  }

  async fetchData(): Promise<void> {
    this.apiProvider.getAllProjects()
      .then((data: Project[]) => {
        this.projects = data
        this.tryUpdateProjectStateFromStore()
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  tryUpdateProjectStateFromStore(): void {
    if (this.notificatorStore.notifications != null && this.notificatorStore.notifications.length > 0) {
      this.notificatorStore.notifications.forEach(x => {
        const project = this.projects.find(p => p.id === x.projectId)
        if (project != null) {
          project.unreadAlarmCount = x.alarmCount
          project.unreadMessageCount = x.messageCount
        }
      })
    }
  }

  onNotificatorStoreNotificationsUpdate(value: ProjectNotificationStatus[] | null): void {
    if (value == null) return;

    value.forEach(x => {
      const project = this.projects.find(p => p.id === x.projectId)
      if (project != null) {
        project.unreadAlarmCount = x.alarmCount
        project.unreadMessageCount = x.messageCount
      }
    })
  }

  async onLoggedStateChanged(isLogged: boolean, wasLogged: boolean): Promise<void> {
    if (!wasLogged && isLogged) {
      await this.fetchData()
    }
  }

  resetModal(): void {
    this.formName = null
    this.formFullAddress = null
  }

  async handleOk(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleSubmit()
  }

  async handleSubmit(): Promise<void> {
    const valid = await (this.$refs.rules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.createProject()
  }

  async createProject(): Promise<void> {
    this.apiProvider.createProject({
      name: this.formName as string,
      full_address: this.formFullAddress as string,
    })
      .then((createdProject: Project) => {
        this.projects.push(createdProject)
        this.resetModal()
        this.$nextTick(() => {
          this.$bvModal.hide('modal-create-project')
        })
        this.makeToast('Project created successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }
}
