import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Notificator from '@/store/modules/Notificator'
import Plexus from '@/store/modules/Plexus'
import Alarms from '@/views/Alarms.vue'
import Backups from '@/views/Backups.vue'
import Blackboxes from '@/views/Blackboxes.vue'
import Devices from '@/views/Devices.vue'
import MixedCharts from '@/views/MixedCharts.vue'
import Profile from '@/views/Profile.vue'
import Projects from '@/views/Projects.vue'
import Reports from '@/views/Reports.vue'
import ReportSections from '@/views/ReportSections.vue'
import Settings from '@/views/Settings.vue'
import Users from '@/views/Users.vue'
import { UserLevel } from '@/models/Enums'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'projects',
      // component: () => import('@/views/Projects.vue'),
      component: Projects,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
      }
    },
    {
      path: '/project/:projectId/devices',
      name: 'devices',
      // component: () => import('@/views/Devices.vue'),
      component: Devices,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/users',
      name: 'users',
      // component: () => import('@/views/Users.vue'),
      component: Users,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/alarms',
      name: 'alarms',
      // component: () => import('@/views/Alarms.vue'),
      component: Alarms,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/backups',
      name: 'backups',
      // component: () => import('@/views/Backups.vue'),
      component: Backups,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/blackboxes',
      name: 'blackboxes',
      // component: () => import('@/views/Blackboxes.vue'),
      component: Blackboxes,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/mixedCharts',
      name: 'mixedCharts',
      // component: () => import('@/views/MixedCharts.vue'),
      component: MixedCharts,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/reports',
      name: 'reports',
      // component: () => import('@/views/Reports.vue'),
      component: Reports,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },
    {
      path: '/project/:projectId/report/:reportId/sections',
      name: 'report-sections',
      // component: () => import('@/views/ReportSections.vue'),
      component: ReportSections,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Supervisor,
      },
    },
    {
      path: '/project/:projectId/settings',
      name: 'settings',
      // component: () => import('@/views/Settings.vue'),
      component: Settings,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
        requiresSelectedProject: true,
        levelRequired: UserLevel.Operator,
      },
    },

    {
      path: '/profile',
      name: 'profile',
      // component: () => import('@/views/Profile.vue'),
      component: Profile,
      meta: {
        requiresAuth: true,
        allowNotificationPolling: true,
      },
    },

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/PathNotFound.vue'),
      meta: {
        layout: 'full',
        allowNotificationPolling: false,
      },
    },
    {
      path: '/error-401',
      name: 'not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        allowNotificationPolling: false,
      },
    },
    {
      path: '/logged',
      name: 'logged',
      component: () => import('@/views/Logged.vue'),
      meta: {
        layout: 'full',
        allowNotificationPolling: false,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.afterEach((to: Route) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  
  if (to.matched.some(record => record.meta?.allowNotificationPolling || false)) {
    // console.log('dispatcho startNotificationsPolling');
    setTimeout(() => {
      getModule(Notificator).startPolling()
    }, 2 * 1000)
  } else {
    // console.log('dispatcho stopNotificationsPolling');
    setTimeout(() => {
      getModule(Notificator).stopPolling()
    }, 2 * 1000)
  }
})

const isRedirectToHomeNeeded = async (to: Route) => {
  if (to.params.projectId != null && !Number.isNaN(Number.parseInt(to.params.projectId, 10))) {
    if (!store.getters['plexus/isProjectSelected'] || (store.getters['plexus/isProjectSelected'] && store.state.plexus.project.id !== Number.parseInt(to.params.projectId, 10))) {
      return new Promise(resolve => {
        store.dispatch('plexus/initProject', Number.parseInt(to.params.projectId, 10))
          .then(() => {
            resolve(false)
          })
          .catch(e => {
            console.warn(e)
            resolve(true)
          })
      })
    }
    return false
  }
  return (to.matched.some(record => record.meta?.requiresSelectedProject || false)) && !store.getters['plexus/isProjectSelected']
}

const rebuildProjectUrlIfNeeded = (to: Route) => {
  if (store.state.plexus.project != null && to.meta != null && to.meta.requiresSelectedProject === true && to.params['projectId'] == null) {
    to.params['projectId'] = store.state.plexus.project.id
  }
}

router.beforeEach(async (to, from, next) => {
  rebuildProjectUrlIfNeeded(to)
  isRedirectToHomeNeeded(to)
    .then(redirect => {
      if (redirect) {
        next({
          path: '/',
        })
      } else {
        if (to.matched.some(record => record.meta?.requiresAuth || false)) {
          if (!store.getters['plexus/isLogged'] && !to.hash.includes('state')) {
            next({
              path: '/error-401',
            })
          }

          const routeWithLevelAuthorization = to.matched.find(record => record.meta?.levelRequired != null);
          if (routeWithLevelAuthorization != null) {
            const levelRequired = routeWithLevelAuthorization.meta.levelRequired;
            const userLoggedLevel = getModule(Plexus).project != null && getModule(Plexus).project?.level != null ? getModule(Plexus).project!.level : 999
            if (userLoggedLevel <= levelRequired) {
              next()
            } else {
              next({
                path: '/error-401',
              })
            }
          } else {
            next()
          }
        } else {
          next()
        }
      }
    })
})

export default router
