/* eslint-disable camelcase */

export interface IRestApiBackup {
  id: number;
  name: string;
  size: number;
  device_id: number;
  device_name: string;
  created_at: string;                     // ISO string
}

export class Backup {
  id: number;
  name: string;
  size: number;
  deviceId: number;
  deviceName: string;
  createdAt: Date;

  constructor(data: IRestApiBackup) {
    this.id = data.id
    this.name = data.name
    this.size = data.size
    this.deviceId = data.device_id
    this.deviceName = data.device_name
    this.createdAt = new Date(data.created_at)
  }
}
