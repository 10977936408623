import Vue from 'vue'
import { getModule } from 'vuex-module-decorators';
import AppConfig from '@/store/modules/AppConfig';
import AppWindow from '@/store/modules/AppWindow';
import Plexus from '@/store/modules/Plexus';
import VerticalMenu from '@/store/modules/VerticalMenu';
import { IRestApiProvider } from '@/providers/IRestApiProvider';
import { RestApiError } from '@/providers/RestApiError';
import HttpStatusCode from '@/providers/HttpStatusCode';
import Notificator from '@/store/modules/Notificator';


export type ElementOffset = {
  left: number;
  top: number
};

export default class BaseView extends Vue {
  menuHidden = true;

  get appWindowStore(): AppWindow {
    return getModule(AppWindow, this.$store);
  }

  get appConfigStore(): AppConfig {
    return getModule(AppConfig, this.$store);
  }

  get plexusStore(): Plexus {
    return getModule(Plexus, this.$store);
  }

  get notificatorStore(): Notificator {
    return getModule(Notificator, this.$store);
  }

  get verticalMenuStore(): VerticalMenu {
    return getModule(VerticalMenu, this.$store);
  }

  get isDark(): boolean {
    return this.appConfigStore.layout.skin === 'dark'
  }

  get isLogged(): boolean {
    return this.plexusStore.isLogged
  }

  get isLoading(): boolean {
    return this.plexusStore.isLoading
  }

  get isResponding(): boolean {
    return this.plexusStore.isResponding
  }

  get isMobile(): boolean {
    return this.appWindowStore.isMobile
  }

  get apiProvider(): IRestApiProvider {
    return this.plexusStore.restApiProvider!
  }

  get loggedUserId(): number | null {
    return this.plexusStore.user?.id || null;
  }

  handleRestApiException(error: RestApiError): void {
    switch (error.responseErrorCode) {
      case -1:
        this.makeDangerToast('Network error, check your connection')
        break
      case HttpStatusCode.INTERNAL_SERVER_ERROR:
        this.makeDangerToast('Internal server error')
        break
      case HttpStatusCode.UNAUTHORIZED:
      case HttpStatusCode.FORBIDDEN:
        this.makeWarningToast('Please check your permissions with the project administrator, or contact us at automation@bioforcetech.com if you think it is application issue', 'Unauthorized operation')
        break
      case HttpStatusCode.BAD_REQUEST:
        this.makeDangerToast('Please check you are filling the entire form, or contact us at automation@bioforcetech.com if you think it is application issue', 'Bad request')
        break
      default:
        if (error.errorMessage != null) {
          this.makeWarningToast(error.errorMessage, `Error ${error.responseErrorCode}`)
        } else {
          this.makeDangerToast(`Something went wrong. ${error.errorMessage}`, `Error ${error.responseErrorCode}`)
        }
        break
    }
  }

  makeToast(content = 'Operation completed successfully', title = 'Notification', variant = 'success', noAutoHide = false, autoHideDelay = 1.5 * 1000): void {
    const hashContent = `${content}${title}${variant}${noAutoHide ? 1 : 0}${autoHideDelay}`;
    const toastHash = (hashContent.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0)).toString();

    this.$root.$bvToast.hide(toastHash);
    this.$root.$bvToast.toast(content, {
      title,
      variant,
      autoHideDelay,
      noAutoHide,
      solid: true,
      id: toastHash,
    })
  }

  makeWarningToast(content: string, title = 'Warning'): void {
    this.makeToast(content, title, 'warning', false, 6 * 1000)
  }

  makeDangerToast(content: string, title = 'Error'): void {
    this.makeToast(content, title, 'danger', true)
  }

  dateToLocaleStringWithUserTimezone(date: Date): string {
    if (this.plexusStore.user == null) return date.toLocaleString()

    // console.log(this.plexusStore.user.timezone);
    const area = this.plexusStore.user.timezone.split('/')[0]

    switch (area) {
      case "America":
        return date.toLocaleString('en-US', { timeZone: this.plexusStore.user.timezone })
      case "Europe":
        return date.toLocaleString('de-DE', { timeZone: this.plexusStore.user.timezone })
      default:
        return date.toLocaleString('en-US', { timeZone: this.plexusStore.user.timezone })
    }
  }

  getElementOffset(el: Element | null): ElementOffset {
    const rect = el?.getBoundingClientRect();

    return {
      left: (rect?.left || 0) + window?.scrollX,
      top: (rect?.top || 0) + window?.scrollY,
    }
  }
}
