/* eslint-disable camelcase */

import { IChart } from "./Chart";

export interface IRestApiMixedChart {
  id: number;
  project_id: number;
  name: string;
  variables: string[];
  first_data_record_at: string; // ISO string
  last_data_record_at: string; // ISO string
  sql_fault: string;
  has_structure_changed: boolean;
  is_producing: boolean;
}

export class MixedChart implements IChart {
  id: number;
  projectId: number;
  name: string;
  variables: string[];
  firstDataRecordAt?: Date;
  lastDataRecordAt?: Date;
  sqlFault: string;
  hasStructureChanged: boolean;
  isProducing: boolean;

  get disabled(): boolean {
    return this.sqlFault != null || this.isProducing;
  }

  get dropdownName(): string {
    return this.name + (this.isProducing ? " ⇨ 🕣" : "")
  }

  get hasData(): boolean {
    return this.firstDataRecordAt != null && this.lastDataRecordAt != null;
  }

  get title(): string {
    return this.sqlFault != null ? this.sqlFault : "";
  }

  constructor(data: IRestApiMixedChart) {
    this.id = data.id;
    this.projectId = data.project_id;
    this.name = data.name;
    this.variables = data.variables;
    this.firstDataRecordAt =
      data.first_data_record_at != null
        ? new Date(data.first_data_record_at)
        : undefined;
    this.lastDataRecordAt =
      data.last_data_record_at != null
        ? new Date(data.last_data_record_at)
        : undefined;
    this.sqlFault = data.sql_fault;
    this.isProducing = data.is_producing;
    this.hasStructureChanged = data.has_structure_changed;
  }
}
