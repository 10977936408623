







import BaseComponent from '@/components/BaseComponent'
import Component from 'vue-class-component'

@Component
export default class FakeBox extends BaseComponent {

}
