import MockApiProvider from '@/providers/mocks/RestApiProvider'
import RestApiProvider from '@/providers/RestApiProvider'
import { IRestApiProvider } from '@/providers/IRestApiProvider'

export default function initProvider(): IRestApiProvider {
  if (process.env.VUE_APP_MOCK_RESTAPIPROVIDER != null && process.env.VUE_APP_MOCK_RESTAPIPROVIDER === 'true') {
    return new MockApiProvider()
  }
  return new RestApiProvider()
}
