import Dygraph from 'dygraphs';
import Component from 'vue-class-component'
import { LAST_SEVEN_DAYS, TimeRange } from '@/models/TimeRanges';
import BaseProjectView from './BaseProjectView';
import { DownloadProvider } from '@/providers/DownloadProvider';
import { IChart } from '@/models/Chart';
import { ChartRange, ReportEvaluator } from '@/models/ReportEvaluator';
import { Report } from '@/models/Report';

interface DataSnapshot {
  data: string;
  dataWithTs: string;
  startZoom: number;
  endZoom: number;
  recordCount: number;
  recordFrequency: number;
}

interface FilterExportOption {
  name: string;
  value: number;
}

@Component
export default class BaseChartView extends BaseProjectView {
  selectedChart: IChart | null = null;
  plottedChart: any = null;

  defaultTimeRange: TimeRange = LAST_SEVEN_DAYS;
  chartTimeRange: TimeRange = {...this.defaultTimeRange};

  reportEvaluator: ReportEvaluator | null = null;

  dygraph: any = null;
  canZoom = false;
  startZoom = this.chartTimeRange.start;
  endZoom = this.chartTimeRange.end;
  chartPoints = 0;
  existDataInRange = false;
  chartLines: string[] = [];
  selectedChartLines: any[] | null = null;
  chartColorPalette = ['#d81b60', '#2e7d32', '#ff5c8d', '#60ad5e', '#a00037', '#005005'];
  customStart: any = null;
  customEnd: any = null;

  dataSnapshots: DataSnapshot[] = [];
  dataSnapshotsCurrentIndex = -1;
  exportResamplingDropdownItems = [
    { name: "One sample every 10 minutes", value: 60 * 10 },
    { name: "One sample every 30 minutes", value: 60 * 30 },
    { name: "One sample every 60 minutes", value: 60 * 60 },
    { name: "One sample every 2 hours", value: 60 * 120 },
  ];
  exportDownsamplingDropdownItems = [
    { name: "One sample every 10 displayed", value: 10 },
    { name: "One sample every 25 displayed", value: 25 },
    { name: "One sample every 50 displayed", value: 50 },
    { name: "One sample every 100 displayed", value: 100 },
  ];

  get chartDropdownText(): string {
    if (!this.selectedChart) {
      return "Charts";
    }
    
    return this.selectedChart.name;
  }
  
  get currentRecordCount(): number {
    return this.currentSnapshot?.recordCount || 0;
  }
  
  get currentRecordFrequency(): number {
    return this.currentSnapshot?.recordFrequency || 0;
  }

  get showSnapshotButtons(): boolean {
    return this.dataSnapshots.length > 1
  }

  get isPreviousSnapshotEnabled(): boolean {
    return this.dataSnapshots.length > 1 && this.dataSnapshotsCurrentIndex > 0
  }

  get isNextSnapshotEnabled(): boolean {
    return this.dataSnapshots.length > 1 && this.dataSnapshotsCurrentIndex < this.dataSnapshots.length - 1
  }

  get previousSnapshotButtonLabel(): string {
    return this.isPreviousSnapshotEnabled ? ` ${this.dataSnapshotsCurrentIndex}` : " _"
  }

  get previousSnapshotTitle(): string {
    return this.isPreviousSnapshotEnabled ? "Load snapshot: previous zoomed data" : "No previous snapshot to load"
  }

  get nextSnapshotButtonLabel(): string {
    return this.isNextSnapshotEnabled ? ` ${this.dataSnapshotsCurrentIndex + 2}` : " _"
  }

  get nextSnapshotTitle(): string {
    return this.isNextSnapshotEnabled ? "Load snapshot: next zoomed data" : "No next snapshot to load"
  }

  get currentSnapshot(): DataSnapshot | null {
    if (this.dataSnapshotsCurrentIndex < 0) return null;

    return this.dataSnapshots[this.dataSnapshotsCurrentIndex];
  }

  protected async loadChartData?(): Promise<void>;

  drawDygraph(data: string): void {
    this.dygraph = new Dygraph("dygraph", data, {
      labelsDiv: 'dygraphs_label',      // Show data labels in an external div, rather than on the graph
      labelsKMB: true,                  // Show K/M/B for thousands/millions/billions on y-axis
      legend: "always",                 // "always", "follow", "onmouseover"
      rightGap: 10,
      highlightCircleSize: 5,
      animatedZooms: true,
      zoomCallback: this.zoomCallback,
      colors: this.chartColorPalette,
      height: window.innerHeight - (this.isMobile ? 430 : 330),
    });
  }

  loadDataSnapshot(index: number): void {
    if (index >= 0 && index <= this.dataSnapshots.length - 1) {
      const snapshot = this.dataSnapshots[index] as DataSnapshot;
      this.startZoom = snapshot.startZoom;
      this.endZoom = snapshot.endZoom;
      this.drawDygraph(snapshot.data);
      this.dataSnapshotsCurrentIndex = index;
      this.canZoom = false;
      this.onFilterChanged(this.selectedChartLines as string[]);
    } else {
      this.makeWarningToast(`No snapshot with selected index: ${index}`);
    }
  }

  selectTimeRange(timeRange: TimeRange): void {
    if (!this.chartTimeRange.isCustom && this.chartTimeRange.label == timeRange.label) {
      return
    }
    
    if (timeRange.isCustom === true) {
      if (this.selectedChart?.hasData || false) {
        this.handleCustomRangeSelection()
      } else {
        this.makeWarningToast("The blackbox has no records, there's no data to show")
      }
      return
    }

    this.chartTimeRange = timeRange
    this.loadChartData!()
  }

  selectChart(chart: IChart, forceRefresh = false): void {
    if (this.selectedChart != null && this.selectedChart.id == chart.id && !forceRefresh) {
      return
    }

    this.selectedChart = chart
    this.selectedChartLines = null
    // this.chartTimeRange = {...this.defaultTimeRange}
    this.dataSnapshots = [];
    this.loadChartData!()
  }

  storeDataSnapshot(data: string): void {
    if (this.dataSnapshots.length >= 5) {
      this.dataSnapshots.shift()
    }
    
    const recordCount = data.split('\n').length - 1;
    const recordData = data.split('\n').map(x => x.split(',').slice(1).join(',')).join('\n');
    
    const firstTs = parseInt(data.split('\n')[1].split(',')[0]);
    const lastTs = parseInt(data.split('\n')[recordCount - 1].split(',')[0]);
    
    this.dataSnapshots.push({
      startZoom: this.startZoom as number,
      endZoom: this.endZoom as number,
      data: recordData,
      dataWithTs: data,
      recordCount,
      recordFrequency: Math.round((lastTs - firstTs) / recordCount)
    });

    this.dataSnapshotsCurrentIndex = this.dataSnapshots.length - 1;
  }

  loadDataOnChartExtremes(): void {
    const customTimeRange: TimeRange = {
      label: '(Custom zoom)',
      start: this.startZoom,
      end: this.endZoom,
    }
    this.chartTimeRange = customTimeRange
    this.loadChartData!()
  }

  zoomCallback(minDate: number, maxDate: number): void {
    const prevStartZoom = this.startZoom
    const prevEndZoom = this.endZoom
    this.startZoom = Math.round(minDate / 1000) * 1000
    this.endZoom = Math.round(maxDate / 1000) * 1000
    if (prevStartZoom != this.startZoom || prevEndZoom != this.endZoom) {
      this.canZoom = true
    }
  }

  onFilterChanged(value: string[]): void {
    for (let i = 0; i < this.chartLines.length; i++) {
      this.dygraph.setVisibility(i, value.includes(this.chartLines[i]))
    }
  }

  async renderChartPlot(): Promise<void> {
    const el = this.$refs.htmlToPrint;
    // add option type to get the image version, if not provided the promise will return the canvas.
    const options = {
      type: 'dataURL'
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.plottedChart = await this.$html2canvas(el, options);
  }

  exportAll(): void {
    if (this.selectedChart == null || this.currentSnapshot == null) return;

    const allItems = this.currentSnapshot.data.split('\n').slice(1);

    let csv = `Localized time|${this.chartLines.join('|')}\n`;
    allItems.forEach((record: string) => {
      csv += record.split(',').join('|') + '\n';
    });

    DownloadProvider.downloadAsCsv(csv, this.selectedChart.name);

    this.$nextTick(() => {
      this.$bvModal.hide('modal-export-data')
    })
  }

  exportByDownsampling(opt: FilterExportOption): void {
    if (this.selectedChart == null || this.currentSnapshot == null) return;

    const filteredItems = this.currentSnapshot.data.split('\n').slice(1);
    
    let csv = `Localized time|${this.chartLines.join('|')}\n`;
    let mod = 0;
    filteredItems.forEach((record: string) => {
      if ((mod % opt.value) == 0) {
        csv += record.split(',').join('|') + '\n';
      }

      mod++;
    });

    DownloadProvider.downloadAsCsv(csv, `${this.selectedChart.name} (${opt.name})`);

    this.$nextTick(() => {
      this.$bvModal.hide('modal-export-data')
    })
  }

  exportByResampling(opt: FilterExportOption): void {
    if (this.selectedChart == null || this.currentSnapshot == null) return;

    const records = this.currentSnapshot.dataWithTs.split('\n').slice(1)
    
    let tsToKeep = parseInt(records[0].split(',')[0])    // the first record to keep is the very first record
    const jumpOffsetTs = opt.value

    let csv = `Localized time|${this.chartLines.join('|')}\n`
    
    records.forEach((record: string) => {
      const recordTs = parseInt(record.split(',')[0])
      if (recordTs >= tsToKeep) {
        csv += record.split(',').slice(1).join('|') + '\n'
        tsToKeep += jumpOffsetTs
      }
    });

    DownloadProvider.downloadAsCsv(csv, `${this.selectedChart.name} (${opt.name})`)

    this.$nextTick(() => {
      this.$bvModal.hide('modal-export-data')
    })
  }
  
  handleCustomRangeSelection(): void {
    const h = this.$createElement
    
    const confirmDialogVNodes = h('div', { class: ['foobar', 'text-center'] }, [
      h('flat-pickr', {
        class: ['text-center', 'full-width'],
        props: {
          value: null,
          config: {
            minDate: this.selectedChart?.firstDataRecordAt || undefined,
            maxDate: this.selectedChart?.lastDataRecordAt || undefined,
            mode: 'range'
          },
        },
        nativeOn: {
          change: (event: any): void => {
            const flatPickrRef = event.srcElement._flatpickr;
            if (flatPickrRef.selectedDates != null && Array.isArray(flatPickrRef.selectedDates)) {
              if (flatPickrRef.selectedDates.length === 2 && flatPickrRef.selectedDates[0].valueOf() != flatPickrRef.selectedDates[1].valueOf()) {
                this.customStart = flatPickrRef.selectedDates[0].valueOf()
                this.customEnd = flatPickrRef.selectedDates[1].valueOf()
                // console.log("two date");
              } else {
                this.customStart = flatPickrRef.selectedDates[0].valueOf()
                this.customEnd = this.customStart + (1000 * 60 * 60 * 24) - 1000
                // console.log("single date");
              }
              // console.log("this.customStart", this.customStart);
              // console.log("this.customEnd", this.customEnd);
            }
          },
        }
      })
    ])
    this.$bvModal.msgBoxConfirm([confirmDialogVNodes], {
      title: 'Choose a custom time range',
      okVariant: 'success',
      okTitle: 'GO',
      cancelTitle: 'CANCEL',
      buttonSize: 'sm',
      centered: true,
      size: 'sm',
      noCloseOnBackdrop: true,
      noCloseOnEsc: true,
      hideHeaderClose: true,
    })
      .then(value => {
        if (value === true) {
          this.chartTimeRange = {
            label: "Custom range",
            start: this.customStart,
            end: this.customEnd,
            isCustom: true,
          }
          this.loadChartData!()
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  resetDygraphDOMBlock() {
    this.selectedChartLines = null
    this.existDataInRange = false
    this.dataSnapshots = []
    this.dataSnapshotsCurrentIndex = -1
    this.chartPoints = 0

    try {
      document.getElementById('dygraph')!.innerHTML = ''
      document.getElementById('dygraphs_label')!.innerHTML = ''
    } catch (e) {
      console.error(e)
    }
  }

  openReportEvaluationModal(report: Report): void {
    const reportTimeRange: ChartRange = {
      start: this.startZoom as number,
      end: this.endZoom as number
    }

    if (reportTimeRange.end === 0) {
      const now = Date.now()
      reportTimeRange.start = now - (reportTimeRange.start as number * 1000)
      reportTimeRange.end = now
    }
    
    this.reportEvaluator = new ReportEvaluator(this.project.id, report, this.apiProvider, reportTimeRange);
    this.renderChartPlot()
      .then(() => {
        this.$root.$bvModal.show('modal-evaluate-report')
      })
      .catch(e => {
        console.error(e);
        this.$root.$bvModal.show('modal-evaluate-report')
      })
  }
}
