/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Project } from '@/models/Project'
import { ProjectInvitation } from '@/models/ProjectInvitation'
import { IDeviceVariables, Device } from '@/models/Device'
import { User } from '@/models/User'
import BaseRestApiProvider from '@/providers/BaseRestApiProvider'
import {
  CreateDevicePayload, UpsertMixedChartPayload, CreateProjectInvitationPayload, CreateProjectPayload, CreateReportPayload, UpdateDevicePayload, UpdateProjectPayload, UpdateReportPayload, UpsertReportSectionPayload, UserPayload, VariableEvaluationPayload, 
} from '@/providers/RestApiPayloads'
import { DeviceTunnelRequestStatus } from '@/models/Enums'
import { IRestApiProvider } from '../IRestApiProvider'
import { Alarm } from '@/models/Alarm'
import { ProjectNotificationStatus } from '@/models/ProjectNotificationStatus'
import { Blackbox } from '@/models/Blackbox'
import { Backup } from '@/models/Backup'
import { Report, ReportSection } from '@/models/Report'
import { IRestVariablesEvaluation } from '@/models/ReportEvaluator'
import { MixedChart } from '@/models/MixedChart'
import { TimeRange } from '@/models/TimeRanges'

const mockProjects = require('./projects.json')

export default class MockApiProvider extends BaseRestApiProvider implements IRestApiProvider {
  async getMe(): Promise<User> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateMe(payload: UserPayload): Promise<User> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async setTimezoneLastLogin(): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllTimezones(): Promise<string[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllProjects(): Promise<Project[]> {
    this.setLoading(true)
    try {
      const data = mockProjects.map((x: any) => new Project(x))
      return data
    } catch (e) {
      const err = BaseRestApiProvider.composeCustomError(e)
      throw err
    } finally {
      this.setLoading(false)
    }
  }

  async getProject(projectId: number): Promise<Project> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteProject(id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async createProject(payload: CreateProjectPayload): Promise<Project> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateProject(id: number, payload: UpdateProjectPayload): Promise<Project> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllProjectInvitations(projectId: number): Promise<ProjectInvitation[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async createProjectInvitation(projectId: number, payload: CreateProjectInvitationPayload): Promise<ProjectInvitation> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteProjectInvitation(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllDevices(projectId: number): Promise<Device[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async createDevice(projectId: number, payload: CreateDevicePayload): Promise<Device> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateDevice(projectId: number, id: number, payload: UpdateDevicePayload): Promise<Device> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateDeviceTunnelRequestStatus(projectId: number, id: number, value: DeviceTunnelRequestStatus): Promise<Device> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteDevice(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllAlarms(projectId: number): Promise<Alarm[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteAlarm(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async clearAllAlarms(projectId: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getNotifications(): Promise<ProjectNotificationStatus[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async setLastViewedNotification(projectId: number, notificationId: number): Promise<Project> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getNotificationBlacklistLevel(projectId: number): Promise<number> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async setNotificationBlacklistLevel(projectId: number, level: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllBackups(projectId: number): Promise<Backup[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async downloadBackup(projectId: number, id: number): Promise<any> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteBackup(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async clearAllBackups(projectId: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllBlackboxes(projectId: number): Promise<Blackbox[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllMixedCharts(projectId: number): Promise<MixedChart[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getMixedChart(projectId: number, mixedChartId: number): Promise<MixedChart> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getChartData(type: string, projectId: number, chartId: number, timeRange: TimeRange): Promise<any> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async createMixedChart(projectId: number, payload: UpsertMixedChartPayload): Promise<MixedChart> {
    return Promise.reject(new Error('Mock not implemented'))
  }
  
  async updateMixedChart(projectId: number, id: number, payload: UpsertMixedChartPayload): Promise<MixedChart> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async deleteMixedChart(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllDeviceAndVariablesList(projectId: number): Promise<IDeviceVariables[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getAllReports(projectId: number): Promise<Report[]> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async createReport(projectId: number, payload: CreateReportPayload): Promise<Report> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateReport(projectId: number, id: number, payload: UpdateReportPayload): Promise<Report> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateReportStatus(projectId: number, id: number, status: string): Promise<Report> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async updateReportLastExecution(projectId: number, id: number): Promise<Report> {
    return Promise.reject(new Error('Mock not implemented'))
  }
  
  async deleteReport(projectId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getReport(projectId: number, reportId: number): Promise<Report> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async getDeviceAndVariablesList(projectId: number, deviceId: number): Promise<IDeviceVariables> {
    return Promise.reject(new Error('Mock not implemented'))
  }
  
  async upsertReportSection(projectId: number, reportId: number, payload: UpsertReportSectionPayload): Promise<ReportSection> {
    return Promise.reject(new Error('Mock not implemented'))
  }
  
  async deleteReportSection(projectId: number, reportId: number, id: number): Promise<void> {
    return Promise.reject(new Error('Mock not implemented'))
  }

  async evaluateVariables(projectId: number, payload: VariableEvaluationPayload): Promise<IRestVariablesEvaluation> {
    return Promise.reject(new Error('Mock not implemented'))
  }
}
