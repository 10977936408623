























































































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import BaseChartView from '@/views/BaseChartView'
import ReportEvaluationCard from '@/components/ReportEvaluationCard.vue'
import { MixedChart } from '@/models/MixedChart'
import { RestApiError } from '@/providers/RestApiError'
import { Report } from '@/models/Report'
import { IDeviceVariables } from '@/models/Device'
import { LAST_HOUR, LAST_FOURTEEN_DAYS, TimeRange, LAST_SIX_HOURS, LAST_TWENTYFOUR_HOURS, LAST_SEVEN_DAYS } from '@/models/TimeRanges';

@Component({
  components: {
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ReportEvaluationCard,
  },
})
export default class MixedCharts extends BaseChartView {
  mixedCharts: MixedChart[] = [];
  reports: Report[] = [];

  defaultTimeRange: TimeRange = LAST_FOURTEEN_DAYS;
  chartTimeRange: TimeRange = {...this.defaultTimeRange};
  allTimeRanges: TimeRange[] = [
    LAST_HOUR, LAST_SIX_HOURS, LAST_TWENTYFOUR_HOURS, LAST_SEVEN_DAYS, LAST_FOURTEEN_DAYS, {
      label: "Custom range", isCustom: true
    }
  ]

  varLimit = 30;
  mixedChartsLimit = 10;
  formName: string | null = null;
  formDevice: IDeviceVariables | null = null;
  formVariables: Map<number, string[]> = new Map<number, string[]>();
  allDeviceVariables: IDeviceVariables[] = [];

  get reportDropdownItems(): Report[] {
    if (this.selectedChart == null || this.reports == null) return [];
    
    return this.reports.filter(x => x.mixedChartId === (this.selectedChart as MixedChart)?.id);
  }

  get deleteModalContent(): string {
    return `Are you sure you want remove the mixed chart "${this.selectedChart?.name}" from the project "${this.project.name}"?`
  }

  get devicesDropdownContent(): IDeviceVariables[] {
    if (this.allDeviceVariables.length == 0) {
      return []
    }

    return this.allDeviceVariables.sort((a: IDeviceVariables, b: IDeviceVariables) => {
      return a.deviceName.localeCompare(b.deviceName)
    });
  }

  get deviceVariablesDropdownContent(): string[] {
    if (this.formDevice == null) {
      return []
    }

    return this.formDevice.variables.sort((a: string, b: string) => {
      return a.localeCompare(b)
    });
  }

  formAddedVariables: string[] = [];

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.initPage)
  }

  async mounted(): Promise<void> {
    await this.initPage()
  }

  async initPage(): Promise<void> {
    await this.fetchData()
  }

  get isVariableSetDisabled(): boolean {
    return this.formAddedVariables.length >= this.varLimit
  }

  selectMixedChart(chart: MixedChart) {
    if (chart.disabled) {
      return
    }
    this.selectChart(chart)
  }

  updateFormAddedVariables(): void {
    this.formAddedVariables = [];

    this.formVariables.forEach((variables: string[], deviceId: number) => {
      const relatedDevice = this.allDeviceVariables.find(d => d.deviceId === deviceId);
      if (relatedDevice == null) {
        this.makeDangerToast('Something went wrong in the page; relatedDevice is expected to be valid. Contact us for support, thank you.');
        return [];
      }

      this.formAddedVariables = [
        ...this.formAddedVariables,
        ...variables.map(v => `${relatedDevice.deviceShortLabel}: ${v}`)
      ]
    })
  }

  modalRemoveDeviceVariable(variable: string): void {
    const [deviceShortLabel, varName] = variable.split(': ');
    const relatedDevice = this.allDeviceVariables.find(d => d.deviceShortLabel === deviceShortLabel);
    if (relatedDevice == null) {
      this.makeDangerToast('Something went wrong in the page; relatedDevice is expected to be valid. Contact us for support, thank you.');
      return;
    }

    let actualDeviceSelectedVars: string[] = []
    if (this.formVariables.has(relatedDevice.deviceId)) {
      actualDeviceSelectedVars = this.formVariables.get(relatedDevice.deviceId)!;
    }

    const indexToRemove = actualDeviceSelectedVars.indexOf(varName)
    if (indexToRemove > -1) {
      actualDeviceSelectedVars.splice(indexToRemove, 1);
    }

    if (actualDeviceSelectedVars.length == 0) {
      this.formVariables.delete(relatedDevice.deviceId);
    } else {
      this.formVariables.set(relatedDevice.deviceId, actualDeviceSelectedVars);
    }
    this.updateFormAddedVariables();
  }

  resetModal(): void {
    this.formName = null
    this.formDevice = null
    this.formVariables = new Map<number, string[]>()
    this.formAddedVariables = []
  }

  modalSelectDevice(device: IDeviceVariables): void {
    this.formDevice = device
  }

  modalAddDeviceVariable(deviceVariable: string): void {
    if (this.isVariableSetDisabled) {
      this.makeWarningToast(`Limit of ${this.varLimit} variables reached.`)
      return;
    }

    if (this.formDevice == null) {
      this.makeDangerToast('Something went wrong in the page. this.formDevice is expected to be valid.')
      return;
    }

    let actualDeviceSelectedVars: string[] = []
    if (this.formVariables.has(this.formDevice!.deviceId)) {
      actualDeviceSelectedVars = this.formVariables.get(this.formDevice!.deviceId)!;
    }
    if (!actualDeviceSelectedVars.includes(deviceVariable)) {
      actualDeviceSelectedVars.push(deviceVariable);
    }

    this.formVariables.set(this.formDevice!.deviceId, actualDeviceSelectedVars);
    this.updateFormAddedVariables();
  }

  async openCreateMixedChartModal(): Promise<void> {
    if (this.mixedCharts.length >= this.mixedChartsLimit) {
      this.makeWarningToast(`Limit of ${this.mixedChartsLimit} charts reached.`)
      return;
    }

    this.apiProvider.getAllDeviceAndVariablesList(this.project.id)
      .then(data => {
        this.$root.$bvModal.show('modal-create-mixed-chart')
        this.allDeviceVariables = data.sort((a, b) => {
          return a.deviceName.localeCompare(b.deviceName);
        });
        this.formDevice = this.allDeviceVariables[0];
      })
      .catch((error: RestApiError) => this.handleRestApiException(error));
  }

  async handleCreate(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleCreateSubmit()
  }

  async handleCreateSubmit(): Promise<void> {
    const valid = await (this.$refs.createRules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.createMixedChart()
  }

  async createMixedChart(): Promise<void> {
    this.apiProvider.createMixedChart(this.project.id, {
      name: this.formName as string,
      variables: this.formVariables,
    })
      .then((entity: MixedChart) => {
        this.mixedCharts.push(entity)
        this.closeCreateDialog()
        this.makeToast(`Mixed chart ${entity.name} created successfully. Plexus Cloud will populate data starting from 14 days ago, so you may wait up to approx. 2 hours to view the latest records, depending on how many variables you have added.`, 'Notification', 'success', true)
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  async openUpdateMixedChartModal(): Promise<void> {
    this.apiProvider.getAllDeviceAndVariablesList(this.project.id)
      .then(data => {
        this.$root.$bvModal.show('modal-update-mixed-chart')
        this.allDeviceVariables = data.sort((a, b) => {
          return a.deviceName.localeCompare(b.deviceName);
        });
        this.formDevice = this.allDeviceVariables[0];

        this.formName = this.selectedChart!.name
        this.formVariables = new Map<number, string[]>()
        this.selectedChart!.variables.forEach((varNameWithDevicePrefix: string) => {
          const [deviceShortLabel, varName] = varNameWithDevicePrefix.split(': ')

          const relatedDevice = this.allDeviceVariables.find(d => d.deviceShortLabel === deviceShortLabel);
          if (relatedDevice == null) {
            console.log(`${varName} skipped, old related device label "${deviceShortLabel}" can't be found.`);
            return;
          }

          let actualDeviceSelectedVars: string[] = []
          if (this.formVariables.has(relatedDevice.deviceId)) {
            actualDeviceSelectedVars = this.formVariables.get(relatedDevice.deviceId)!;
          }
          actualDeviceSelectedVars.push(varName)
          this.formVariables.set(relatedDevice.deviceId, actualDeviceSelectedVars);
        })
        this.updateFormAddedVariables();
      })
      .catch((error: RestApiError) => this.handleRestApiException(error));
  }

  async handleUpdate(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleUpdateSubmit()
  }

  async handleUpdateSubmit(): Promise<void> {
    const valid = await (this.$refs.updateRules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    await this.updateMixedChart()
  }

  async updateMixedChart(): Promise<void> {
    this.apiProvider.updateMixedChart(this.project.id, this.selectedChart!.id, {
      name: this.formName as string,
      variables: this.formVariables,
    })
      .then((entity: MixedChart) => {
        this.fetchData(entity.hasStructureChanged ? 0 : entity.id)
        this.closeUpdateDialog()
        this.makeToast(`Mixed chart ${entity.name} updated successfully.` + (entity.hasStructureChanged ? ' Plexus Cloud will re-populate data starting from 14 days ago, so you may wait up to approx. 2 hours to view the latest records.' : ''), 'Notification', 'success', entity.hasStructureChanged)
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  openDeleteMixedChartModal(): void {
    this.$bvModal.show('modal-delete-mixed-chart')
  }

  async deleteMixedChart(): Promise<void> {
    if (this.selectedChart == null) return

    const id = this.selectedChart.id
    this.apiProvider.deleteMixedChart(this.project.id, id)
      .then(() => {
        this.fetchData()
        this.closeDeleteDialog()
        this.makeToast('Mixed chart deleted successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  closeCreateDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-create-mixed-chart')
    })
  }

  closeUpdateDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-update-mixed-chart')
    })
  }

  closeDeleteDialog(): void {
    this.selectedChart = null
    this.$nextTick(() => {
      this.$bvModal.hide('modal-delete-mixed-chart')
    })
  }

  async fetchData(chartId = 0): Promise<void> {
    const promises: any[] = [
      this.apiProvider.getAllMixedCharts(this.project.id),
      this.apiProvider.getAllReports(this.project.id),
    ]

    Promise.all(promises)
      .then(results => {
        this.mixedCharts = results[0]
        if (this.mixedCharts.length > 0) {
          if (chartId === 0) {
            const eligibleChart = this.mixedCharts.find(chart => !chart.disabled);
            if (eligibleChart) {
              this.selectChart(eligibleChart)
            } else {
              this.selectedChart = null;
              this.resetDygraphDOMBlock();
            }
          } else if (this.mixedCharts.find(c => c.id === chartId)) {
            this.selectChart(this.mixedCharts.find(c => c.id === chartId)!, true)
          }
        }

        this.reports = results[1].filter((x: Report) => x.status === 'published')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error));
  }

  override async loadChartData(): Promise<void> {
    if (this.selectedChart == null) {
      return
    }
    if (this.chartTimeRange == null) {
      this.chartTimeRange = LAST_HOUR
    }

    this.startZoom = this.chartTimeRange.start
    this.endZoom = this.chartTimeRange.end

    try {
      const data = await this.apiProvider.getChartData('mixed_charts', this.project.id, this.selectedChart.id, this.chartTimeRange)

      if (data == null || data == "") {
        this.makeWarningToast("No data to show for the selected time range")
      } else {
        this.storeDataSnapshot(data as string);
        this.existDataInRange = true
        this.chartLines = (data as string).split("\n")[0].split(",").slice(2)

        if (this.selectedChartLines == null) this.selectedChartLines = JSON.parse(JSON.stringify(this.selectedChart.variables))

        this.drawDygraph(this.currentSnapshot?.data || "");

        this.canZoom = false
        this.onFilterChanged(this.selectedChartLines as string[])
      }
    } catch (error: any) {
      this.handleRestApiException(error as RestApiError)
    }
  }
}
