






import BaseComponent from '@/components/BaseComponent'
import Component from 'vue-class-component'
import { BSpinner } from 'bootstrap-vue'

@Component({
  components: {
    BSpinner
  },
})
export default class SmallSpinner extends BaseComponent {
  get isResponding(): boolean {
    return this.plexusStore.isResponding
  }
}
