/* eslint-disable camelcase */
import { UserLevel } from '@/models/Enums';

export interface IRestApiProjectInvitation {
  id: number;
  user_id: number;
  name: string;
  email: string;
  level: UserLevel;
  // created_at: string;
}

export class ProjectInvitation {
  id: number;
  userId: number;
  name: string;
  email: string;
  level: UserLevel;
  // memberSince: Date;

  constructor(data: IRestApiProjectInvitation) {
    this.id = data.id
    this.userId = data.user_id
    this.name = data.name
    this.email = data.email
    this.level = data.level
    // this.memberSince = DateTime.fromSQL(data.created_at).toJSDate()
  }

  get levelString(): string {
    return UserLevel[this.level];
  }
}
