export enum UserLevel {
  Owner = 0,
  Admin = 10,
  Supervisor = 20,
  Operator = 40,
}

export enum DeviceTunnelRequestStatus {
  Off = 'off',
  On = 'on',
}
