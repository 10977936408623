import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { $themeBreakpoints } from '@themeConfig'

@Module({
  name: 'app',
  namespaced: true,
})
export default class AppWindow extends VuexModule {
  windowWidth = 0
  shallShowOverlay = false

  get isMobile(): boolean {
    return this.currentBreakPoint === 'xs'
  }
  
  get currentBreakPoint(): 'xl' | 'lg' | 'md' | 'sm' | 'xs' {
    if (this.windowWidth >= $themeBreakpoints.xl) return 'xl'
    if (this.windowWidth >= $themeBreakpoints.lg) return 'lg'
    if (this.windowWidth >= $themeBreakpoints.md) return 'md'
    if (this.windowWidth >= $themeBreakpoints.sm) return 'sm'
    return 'xs'
  }

  @Mutation
  UPDATE_WINDOW_WIDTH(val: number): void {
    this.windowWidth = val
  }
  
  @Mutation
  TOGGLE_OVERLAY(val?: boolean): void {
    this.shallShowOverlay = val !== undefined ? val : !this.shallShowOverlay
  }
}
