export interface TimeRange {
  label: string;
  start?: number;
  end?: number;
  isCustom?: boolean;
}

export const LAST_TEN_MINUTES: TimeRange = {
  label: "10 Minutes",
  start: 60 * 10,
  end: 0,
};
export const LAST_HOUR: TimeRange = {
  label: "1 Hour",
  start: 60 * 60,
  end: 0,
};
export const LAST_SIX_HOURS: TimeRange = {
  label: "6 Hours",
  start: 60 * 60 * 6,
  end: 0,
};
export const LAST_TWENTYFOUR_HOURS: TimeRange = {
  label: "24 Hours",
  start: 60 * 60 * 24,
  end: 0,
};
export const LAST_SEVEN_DAYS: TimeRange = {
  label: "7 Days",
  start: 60 * 60 * 24 * 7,
  end: 0,
};
export const LAST_FOURTEEN_DAYS: TimeRange = {
  label: "14 Days",
  start: 60 * 60 * 24 * 14,
  end: 0,
};
export const LAST_THIRTY_DAYS: TimeRange = {
  label: "30 Days",
  start: 60 * 60 * 24 * 30,
  end: 0,
};
