import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { $themeConfig } from '@themeConfig'

@Module({
  name: 'appConfig',
  namespaced: true,
})
export default class AppConfig extends VuexModule {
  layout = {
    isRTL: $themeConfig.layout.isRTL,
    skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
    routerTransition: $themeConfig.layout.routerTransition,
    type: $themeConfig.layout.type,
    contentWidth: $themeConfig.layout.contentWidth,
    menu: {
      hidden: $themeConfig.layout.menu.hidden,
    },
    navbar: {
      type: $themeConfig.layout.navbar.type,
      backgroundColor: $themeConfig.layout.navbar.backgroundColor,
    },
    footer: {
      type: $themeConfig.layout.footer.type,
    },
  }

  @Mutation
  TOGGLE_RTL(): void {
    this.layout.isRTL = !this.layout.isRTL
    document.documentElement.setAttribute('dir', this.layout.isRTL ? 'rtl' : 'ltr')
  }

  @Mutation
  UPDATE_SKIN(skin: string): void {
    this.layout.skin = skin

    // Update value in localStorage
    localStorage.setItem('vuexy-skin', skin)

    // Update DOM for dark-layout
    if (skin === 'dark') document.body.classList.add('dark-layout')
    else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
  }

  @Mutation
  UPDATE_ROUTER_TRANSITION(val: string): void {
    this.layout.routerTransition = val
  }

  @Mutation
  UPDATE_LAYOUT_TYPE(val: string): void {
    this.layout.type = val
  }

  @Mutation
  UPDATE_CONTENT_WIDTH(val: string): void {
    this.layout.contentWidth = val
  }

  @Mutation
  UPDATE_NAV_MENU_HIDDEN(val: boolean): void {
    this.layout.menu.hidden = val
  }

  @Mutation
  UPDATE_NAVBAR_CONFIG(obj: {
    type: string,
    backgroundColor: string,
  }): void {
    Object.assign(this.layout.navbar, obj)
  }

  @Mutation
  UPDATE_FOOTER_CONFIG(obj: {
    type: string
  }): void {
    Object.assign(this.layout.footer, obj)
  }
}
