import { Project } from '@/models/Project';
import { IRestApiProvider } from '@/providers/IRestApiProvider';
import BaseComponent from './BaseComponent';

export default class BaseProjectComponent extends BaseComponent {
  get project(): Project {
    return this.plexusStore.project!
  }

  get apiProvider(): IRestApiProvider {
    return this.plexusStore.restApiProvider!;
  }
  
  get isResponding(): boolean {
    return this.plexusStore.isResponding
  }

  makeToast(content = 'Operation completed successfully', title = 'Notification', variant = 'success', noAutoHide = false, autoHideDelay = 1.5 * 1000): void {
    this.$root.$bvToast.toast(content, {
      title,
      variant,
      autoHideDelay,
      noAutoHide,
      solid: true,
    })
  }

  makeWarningToast(content: string, title = 'Warning'): void {
    this.makeToast(content, title, 'warning', false, 6 * 1000)
  }

  makeDangerToast(content: string, title = 'Error'): void {
    this.makeToast(content, title, 'danger', true)
  }
}
