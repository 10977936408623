import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import { $themeConfig } from '@themeConfig'

@Module({
  name: 'verticalMenu',
  namespaced: true,
})
export default class VerticalMenu extends VuexModule {
  isVerticalMenuCollapsed = $themeConfig.layout.menu.isCollapsed

  @Mutation
  UPDATE_VERTICAL_MENU_COLLAPSED(val: boolean): void {
    this.isVerticalMenuCollapsed = val
  }
}
