/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable camelcase */
import lodash from 'lodash'
import { IRestApiProvider } from "@/providers/IRestApiProvider";
import { EvaluatedReport, EvaluatedReportSection, EvaluatedReportSectionOutput } from "./EvaluatedReport";
import { Report, ReportSection } from "./Report";

export interface IRestValueWithTimestamps {
  value: number;
  ts: number;
  utc: string;
  human: string;
}

interface extendable {
  [key: string]: number | string | Time | TimeRange | Variable[];
}

interface Time {
  ts: number;
  utc: string;
  human: string;
}

interface TimeRange {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
}

interface Variable {
  first_value: number;
  last_value: number;
  avg: number;
  sum: number;
  avg_nozero: number;
  sum_nozero: number;
  on_off: {
    on_time: number;
    on_time_percent: number;
    off_time: number;
    off_time_percent: number;
    off_on_variations: number;
    on_off_variations: number;
  },
  max: IRestValueWithTimestamps,
  max_nozero: IRestValueWithTimestamps,
  min: IRestValueWithTimestamps,
  min_nozero: IRestValueWithTimestamps,
}

export interface IRestVariablesEvaluation extends extendable {
  start_time: Time,
  end_time: Time,
  time_range: TimeRange,
  variables: Variable[]
}

export interface ChartRange {
  start: number;
  end: number;
}

export class ReportEvaluator {
  projectId: number;
  report: Report;
  apiProvider: IRestApiProvider;
  timeRange: ChartRange;

  constructor(projectId: number, report: Report, apiProvider: IRestApiProvider, timeRange: ChartRange) {
    this.projectId = projectId
    this.report = report.clone()
    this.apiProvider = apiProvider
    this.timeRange = timeRange
  }

  private interpolate(formula: string, data: IRestVariablesEvaluation): number | string {
    let result = null;
    try {
      const templateLiteral = lodash.template(formula.replaceAll(': ', '____').replaceAll(':', '____'));
      result = templateLiteral(data);
    } catch (templateE) {
      throw new Error(`Formula "${formula}" contains invalid variables. Check it in the report builder section.`)
    }

    try {
      const evaluated = eval(result);
      return Number.parseFloat(Number.parseFloat(evaluated).toFixed(3));
    } catch (evalE) {
      console.warn(`Formula "${formula}" failed to evaluate as a Number`)
      console.warn(" If the above mentioned formula contains .utc or .human variable keys you can ignore the warning, but keep in mind those variable keys can't be merged with other keys.");
      console.warn("  If you expected the output to be evaluated and it doesn't happen, update your formulas in the report builder section to have .utc or .human as a separate output.");
      console.warn(" If the above mentioned formula doesn't contain .utc or .human variable keys, it's an application error. Please contact us at automation@bioforcetech.com and provide us with your report configuration. Thank you.");
      
      return result;
    }
  }

  private async evaluateSection(evaluatedPreviousSections: EvaluatedReportSection[], section: ReportSection, evaluationRange: ChartRange): Promise<EvaluatedReportSection> {
    // @ts-ignore
    let evaluatedVariables: IRestVariablesEvaluation = {};
    if (section.allOutputFormulaVariablesKeys.length > 0) {
      evaluatedVariables = await this.apiProvider.evaluateVariables(this.projectId, {
        device_id: this.report.deviceId,
        mixed_chart_id: this.report.mixedChartId,
        start: evaluationRange.start,
        end: evaluationRange.end,
        variables: section.allOutputFormulaVariablesKeys,
      });
    }

    const evaluatedOutputs: EvaluatedReportSectionOutput[] = []
    section.outputs.forEach(output => {
      try {
        const evaluatedPreviousSectionOutputWithKeys = evaluatedPreviousSections.flatMap(x => x.evaluatedKeys);
        evaluatedPreviousSectionOutputWithKeys.forEach(x => evaluatedVariables[x.key] = x.value)

        const evaluated = this.interpolate(output.formula, evaluatedVariables)
        evaluatedOutputs.push(new EvaluatedReportSectionOutput({
          name: output.name,
          key: output.key,
          unit: output.unit,
          value: evaluated,
          isVisible: output.isVisible,
        }))
        if (output.key != null) {
          evaluatedVariables[output.key] = evaluated;
        }
      } catch (e) {
        evaluatedOutputs.push(new EvaluatedReportSectionOutput({
          name: output.name,
          error: e,
        }))
      }
    });

    return new EvaluatedReportSection({
      name: section.name,
      start: evaluationRange.start,
      end: evaluationRange.end,
      outputs: evaluatedOutputs
    });
  }

  private async fetchCustomRangeValue(section: ReportSection, which: string): Promise<number | number[]> {
    // try {
      const evaluatedVariableForCustomRange = await this.apiProvider.evaluateVariables(this.projectId, {
        device_id: this.report.deviceId,
        mixed_chart_id: this.report.mixedChartId,
        start: this.timeRange.start,
        end: this.timeRange.end,
        variables: which === 'start' || which === 'both' ? [section.startVariable] : [section.endVariable],
      });

      const startParts = section.start.replaceAll(': ', '____').replaceAll(':', '____').split('.')
      const endParts = section.end.replaceAll(': ', '____').replaceAll(':', '____').split('.')
      
      if (which === 'both') {
        return [
          // @ts-ignore
          evaluatedVariableForCustomRange[startParts[0]][startParts[1]][startParts[2]],
          // @ts-ignore
          evaluatedVariableForCustomRange[endParts[0]][endParts[1]][endParts[2]]
        ]
      }
  
      if (which === 'start') {
        // @ts-ignore
        return evaluatedVariableForCustomRange[startParts[0]][startParts[1]][startParts[2]];
      }
  
      // @ts-ignore
      return evaluatedVariableForCustomRange[endParts[0]][endParts[1]][endParts[2]];
    // } catch (e) {
    //   console.error(e);
    //   return 0
    // }
  }

  async evaluate(): Promise<EvaluatedReport> {
    const result = new EvaluatedReport(this.report);

    for (let sI = 0; sI < this.report.sections.length; sI += 1) {
      const currentSection = this.report.sections[sI];      
      const sectionEvaluationRange: ChartRange = {
        start: this.timeRange.start,
        end: this.timeRange.end,
      }

      if (currentSection.startVariable === currentSection.endVariable) {
        const customRangeExtremes = await this.fetchCustomRangeValue(currentSection, 'both');
        sectionEvaluationRange.start = (customRangeExtremes as number[])[0]
        sectionEvaluationRange.end = (customRangeExtremes as number[])[1]
      } else {
        if (currentSection.start !== 'chart_start') {
          sectionEvaluationRange.start = await this.fetchCustomRangeValue(currentSection, 'start') as number;
        }
  
        if (currentSection.end !== 'chart_end') {
          sectionEvaluationRange.end = await this.fetchCustomRangeValue(currentSection, 'end') as number;
        }
      }

      if (sectionEvaluationRange.start >= sectionEvaluationRange.end) {
        result.addEvaluatedSection(new EvaluatedReportSection({
          name: currentSection.name,
          start: currentSection.start,
          end: currentSection.end,
          errorTitle: 'Invalid time range',
          error: `The starting point "${currentSection.start}" happens after the ending point "${currentSection.end}": review your chart range by using the zoom and retry.`
        }))
        continue;
      }

      const evaluatedPreviousSections = result.evaluatedSections.slice(0, sI)
      try {
        const evaluatedSection = await this.evaluateSection(evaluatedPreviousSections, this.report.sections[sI], sectionEvaluationRange)
        result.addEvaluatedSection(evaluatedSection)
      } catch (e) {
        result.addEvaluatedSection(new EvaluatedReportSection({
          name: currentSection.name,
          start: currentSection.start,
          end: currentSection.end,
          errorTitle: 'Invalid evaluated variables',
          error: `The selected data range generated an error: no data matches query conditions, no results were given.`
        }))
      }
    }

    try {
      await this.apiProvider.updateReportLastExecution(this.projectId, this.report.id);
    } catch (e) {
      // no-op
    }

    return result
  }
}
