import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './modules/AppWindow'
import appConfig from './modules/AppConfig'
import verticalMenu from './modules/VerticalMenu'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
})

export default store
