import { Project } from '@/models/Project'
import BaseView from './BaseView'

export default class BaseProjectView extends BaseView {
  get project(): Project {
    return this.plexusStore.project!
  }
  
  get amiOwner(): boolean {
    return this.project.amiOwner;
  }
  
  get amiAdmin(): boolean {
    return this.project.amiAdmin;
  }
  
  get amiSupervisor(): boolean {
    return this.project.amiSupervisor;
  }
  
  get amiOperator(): boolean {
    return this.project.amiOperator;
  }

  onCreate(): void {
    this.menuHidden = this.appConfigStore.layout.menu.hidden
    this.appConfigStore.UPDATE_NAV_MENU_HIDDEN(false)
  }

  onDestroy(): void {
    // this.appConfigStore.UPDATE_NAV_MENU_HIDDEN(false)
  }
}
