/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Report } from "./Report";

export class EvaluatedReportSectionOutput {
  name: string;
  key?: string;
  unit?: string;
  value?: number;
  error?: string;
  isVisible: boolean;

  get hasErrors(): boolean {
    return this.error != null
  }

  constructor(data: any) {
    this.name = data.name;
    this.unit = data.unit;
    this.key = data.key;
    this.isVisible = data.isVisible == null ? true : data.isVisible;

    if (data.value != null) this.value = data.value;
    if (data.error != null) this.error = data.error;
  }
}

export interface IEvaluatedKeyedOutput {
  key: string;
  value: number;
}

export class EvaluatedReportSection {
  name: string;
  start: string;
  end: string;
  outputs: EvaluatedReportSectionOutput[];
  errorTitle?: string;
  error?: string;

  get evaluatedKeys(): IEvaluatedKeyedOutput[] {
    return this.outputs.filter(x => x.key != null && x.key.length > 0).map(x => {
      return {
        key: x.key as string,
        value: x.value as number
      } as IEvaluatedKeyedOutput
    })
  }

  get hasErrors(): boolean {
    return this.error != null && this.errorTitle != null
  }

  constructor(data: any) {
    this.name = data.name
    this.start = data.start
    this.end = data.end
    this.outputs = data.outputs != null ? data.outputs.map((x: any) => new EvaluatedReportSectionOutput(x)) : []
    
    if (data.error != null && data.errorTitle) {
      this.error = data.error
      this.errorTitle = data.errorTitle
    }
  }
}

export class EvaluatedReport {
  name: string;
  description: string;
  userCreatorName: string;
  deviceId: number;
  deviceName: string;
  blackboxId?: number;
  blackboxName?: string;
  evaluatedSections: EvaluatedReportSection[];

  constructor(report: Report) {
    this.name = report.name
    this.description = report.description
    this.userCreatorName = report.userCreatorName
    this.deviceId = report.deviceId
    this.deviceName = report.deviceName

    this.evaluatedSections = []
  }

  addEvaluatedSection(data: any): void {
    this.evaluatedSections.push(new EvaluatedReportSection(data));
  }
}
