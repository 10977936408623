import {
  Module, VuexModule, Mutation, Action, getModule, 
} from 'vuex-module-decorators'
import * as env from 'env-var'

import store from '@/store'

import { ProjectNotificationStatus } from '@/models/ProjectNotificationStatus'
import Plexus from './Plexus'

@Module({
  name: 'notificator',
  namespaced: true,
  dynamic: true,
  store,
})
export default class Notificator extends VuexModule {
  notificationStatus: ProjectNotificationStatus[] | null = null
  pollingNotificationsInterval: any = null
  canPoll = true

  get allAlarmsCount(): number {
    if (this.notificationStatus == null) return 0;

    return this.notificationStatus.reduce((prev: number, curr: ProjectNotificationStatus) => {
      return prev + curr.alarmCount
    }, 0);
  }

  get allMessagesCount(): number {
    if (this.notificationStatus == null) return 0;

    return this.notificationStatus.reduce((prev: number, curr: ProjectNotificationStatus) => {
      return prev + curr.messageCount
    }, 0);
  }

  get allNotificationsCount(): number {
    return this.allAlarmsCount + this.allMessagesCount
  }

  get notifications(): ProjectNotificationStatus[] | null {
    return this.notificationStatus
  }
  
  @Action({ rawError: true })
  startPolling(): void {
    if (this.pollingNotificationsInterval != null) {
      // console.log('skippo startNotificationsPolling perché già attivo')
      return
    }

    // console.log('startNotificationsPolling')
    this.poll()

    this.SET_POLLING_NOTIFICATION_INTERVAL(setInterval(() => {
      if (this.canPoll === true) {
        this.poll()
      }
    }, env.get('VUE_APP_NOTIFICATION_POLLING_IN_SECONDS').default(20).asInt() * 1000))
  }

  @Action({ rawError: true })
  stopPolling(): void {
    // console.log('stopNotificationsPolling')
    if (this.pollingNotificationsInterval) {
      try {
        // console.log('clearInterval(this.pollingNotificationsInterval)')
        clearInterval(this.pollingNotificationsInterval)
        this.SET_POLLING_NOTIFICATION_INTERVAL(null)
      } catch {
        // no op
      }
    }
  }

  @Action({ rawError: true })
  poll(): void {
    this.SET_CAN_POLL(false)
    const plexusStore = getModule(Plexus, store)
    
    if (plexusStore == null || plexusStore.restApiProvider == null) return

    // console.log('poll')
    plexusStore.restApiProvider.getNotifications()
      .then(notifications => {
        this.SET_NOTIFICATIONS(notifications)
        this.SET_CAN_POLL(true)
      })
      .catch(() => this.SET_CAN_POLL(false))
  }

  @Mutation
  STORE_NOTIFICATION_STATUS(notificationStatus: ProjectNotificationStatus[]): void {
    this.notificationStatus = notificationStatus
  }

  @Mutation
  SET_CAN_POLL(value: boolean): void {
    this.canPoll = value
  }

  @Mutation
  SET_POLLING_NOTIFICATION_INTERVAL(value: any): void {
    this.pollingNotificationsInterval = value
  }

  @Mutation
  SET_NOTIFICATIONS(value: ProjectNotificationStatus[]): void {
    this.notificationStatus = value
  }
}
