




















































import BaseProjectComponent from '@/components/BaseProjectComponent'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import jsPDF from 'jspdf'

import { ReportEvaluator } from '@/models/ReportEvaluator';
import { EvaluatedReport, EvaluatedReportSection, EvaluatedReportSectionOutput } from '@/models/EvaluatedReport';
import HttpStatusCode from '@/providers/HttpStatusCode';
import { DownloadProvider } from '@/providers/DownloadProvider';

@Component
export default class ReportEvaluationCard extends BaseProjectComponent {
  @Prop() readonly reportEvaluator!: ReportEvaluator;
  @Prop() readonly plottedChart!: any;
  
  evaluatedReport: EvaluatedReport | null = null;

  get timeRangeHuman(): string {
    const start = this.dateToLocaleStringWithUserTimezone(new Date(this.reportEvaluator.timeRange.start))
    const end = this.dateToLocaleStringWithUserTimezone(new Date(this.reportEvaluator.timeRange.end))
    return `from ${start} to ${end}`
  }

  get title(): string {
    if (this.reportEvaluator.report.isRelatedToDevice) {
      return `"${this.reportEvaluator.report.name}" on device "${this.reportEvaluator.report.deviceName}"`
    }

    return `"${this.reportEvaluator.report.name}" on device "${this.reportEvaluator.report.mixedChartName}"`
  }

  get timeRangeMessage(): string {
    return `Time range acquired from the chart: ${this.timeRangeHuman}`
  }
  
  async evaluateReport(): Promise<void> {
    try {
      this.evaluatedReport = await this.reportEvaluator.evaluate();
    } catch (evalError: any) {
      if (evalError != null && evalError.responseErrorCode != null && evalError.responseErrorCode == HttpStatusCode.TOO_MANY_REQUESTS) {
        this.makeWarningToast('You reached the maximum number of requests for the report generation. You can try again in 24 hours or switch to a paid account to remove the limit.', 'Too many requests')
      } else {
        this.makeDangerToast(evalError?.errorMessage?.message || "Internal server error. Please contact us for support, thank you")
      }
    }
  }

  navigateToReportSection(): void {
    this.$router.push({
      name: 'report-sections',
      params: {
        projectId: this.project.id.toString(),
        reportId: this.reportEvaluator.report.id.toString()
      }
    })
  }

  pdfAddFooter(doc: jsPDF): void {
    const prevTextColor = doc.getTextColor()
    const prevFontSize = doc.getFontSize()
    doc.setTextColor(200, 200, 200);
    doc.setFontSize(8);
    doc.text(`© ${new Date(Date.now()).getFullYear()} Bioforcetech, all rights reserved`, 15, 285);
    doc.text(doc.getNumberOfPages().toString(), 195, 285, { align: 'right' });
    doc.setTextColor(prevTextColor)
    doc.setFontSize(prevFontSize)
  }

  downloadPdf(): void {
    if (this.evaluatedReport == null) return;

    const pageWidth = 180
    const pageOffsetHeightLimit = 280
    const lineHeight = 15
    const margin = 10
    // const maxLineWidth = 180
    const maxLineWidth = pageWidth - margin * 2
    const fontSize = 10
    const ptsPerInch = 72
    const oneLineHeight = (fontSize * lineHeight) / ptsPerInch
    const doc = new jsPDF().setProperties({ title: this.title });

    this.pdfAddFooter(doc);

    let offsetY = 20
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(16);
    doc.text(this.title, 105, offsetY, { align: 'center', maxWidth: maxLineWidth });
    doc.setFont("helvetica", "italic");
    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    const descTextLines: any[] = doc.splitTextToSize(this.reportEvaluator.report.description, maxLineWidth);
    offsetY += 7.5
    doc.text(descTextLines, 105, offsetY, { align: 'center' });
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    offsetY += 7.5 + (descTextLines.length * oneLineHeight)
    doc.text(this.timeRangeMessage, 105, offsetY, { align: 'center', maxWidth: 180 });
    
    offsetY += 10
    doc.addImage({
      imageData: this.plottedChart,
      x: 30,
      y: offsetY,
      width: 150,
      height: 80,
    })

    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    offsetY += 95
    doc.text("Results", 105, offsetY, { align: 'center', maxWidth: maxLineWidth });
    doc.setFontSize(12);
    offsetY += 7
    doc.text("Sections", 15, offsetY, { align: 'left', maxWidth: maxLineWidth / 3 });
    doc.text("Outputs", 195, offsetY, { align: 'right', maxWidth: maxLineWidth / 3 * 2 });

    doc.setFont("helvetica", "normal");

    offsetY += 7
    const outputLineHeight = 5
    this.evaluatedReport.evaluatedSections.forEach((evalSection: EvaluatedReportSection) => {
      const sectionPdfHeightNeeded = outputLineHeight * evalSection.outputs.length
      if (offsetY + sectionPdfHeightNeeded > pageOffsetHeightLimit) {
        doc.addPage()
        offsetY = 20
        this.pdfAddFooter(doc);
      }

      doc.setFont("helvetica", "normal");
      doc.setTextColor(50, 50, 50);
      doc.setFontSize(11);
      doc.text(evalSection.name, 15, offsetY, { align: 'left', maxWidth: maxLineWidth / 3 });
      if (evalSection.hasErrors) {
        doc.setFont("helvetica", "bold");
        doc.text(`${evalSection.errorTitle}. ${evalSection.error}`, 195, offsetY, { align: 'right', maxWidth: maxLineWidth / 3 * 2 });
      }

      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      
      evalSection.outputs.filter(x => x.isVisible == null || x.isVisible === true).forEach((evalSectionOutput: EvaluatedReportSectionOutput) => {
        if (evalSectionOutput.hasErrors) {
          doc.setFont("helvetica", "bold");
          doc.text(`${evalSectionOutput.name} => ERROR => ${evalSectionOutput.error}`, 195, offsetY, { align: 'right', maxWidth: maxLineWidth / 3 * 2 });
        } else {
          doc.setFont("helvetica", "normal");
          doc.text(`${evalSectionOutput.name} = ${evalSectionOutput.value}${evalSectionOutput.unit != null ? " " + evalSectionOutput.unit : ""}`,
            195, offsetY, { align: 'right', maxWidth: maxLineWidth / 3 * 2 });
        }
        offsetY += outputLineHeight
      })

      offsetY += 7
    })

    doc.save(`${this.evaluatedReport.name} [${new Date(Date.now()).toISOString()}].pdf`);
  }

  downloadCsv(): void {
    if (this.evaluatedReport == null) return;

    let csv = 'Section|Start|End|SectionError|OutputName|OutputUnit|OutputValue|OutputError\n';
    this.evaluatedReport.evaluatedSections.forEach(evaluatedSection => {
      evaluatedSection.outputs.filter(x => x.isVisible == null || x.isVisible === true).forEach(evaluatedSectionOutput => {
        csv += `${evaluatedSection.name}|${evaluatedSection.start}|${evaluatedSection.end}|${evaluatedSection.hasErrors ? evaluatedSection.errorTitle : ""}|`;
        csv += `${evaluatedSectionOutput.name}|${evaluatedSectionOutput.unit || ""}|${evaluatedSectionOutput.value || ""}|${evaluatedSectionOutput.error || ""}`
        csv += "\n";
      });
    });

    DownloadProvider.downloadAsCsv(csv, this.evaluatedReport.name);
  }
}
