/* eslint-disable camelcase */

export interface IRestApiAlarm {
  id: number;
  type: string;
  name: string;
  description: string;
  duration: number;
  triggered_at: string;                     // ISO string
  email_notification_sent_at: string;       // ISO string
  device_id: number;
  device_name: string;
  is_new: number;
}

export class Alarm {
  id: number;
  type: string;
  name: string;
  description: string;
  duration: number;
  triggeredAt: Date;
  emailNotificationSentAt?: Date;
  deviceId: number;
  deviceName: string;
  isNew: boolean;

  get wasNotified(): boolean {
    return this.emailNotificationSentAt != null
  }

  constructor(data: IRestApiAlarm) {
    this.id = data.id
    this.type = data.type
    this.name = data.name
    this.description = data.description
    this.duration = data.duration
    this.triggeredAt = new Date(data.triggered_at)
    this.deviceId = data.device_id
    this.deviceName = data.device_name
    this.isNew = data.is_new === 1

    if (data.email_notification_sent_at != null) {
      this.emailNotificationSentAt = new Date(data.email_notification_sent_at)
    }
  }
}
