/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SubscriptionStatus } from '@/models/Subscription'

export interface IRestApiUser {
  id: number;
  name: string;
  email: string;
  timezone: string;
}

export class User {
  id: number;
  name: string;
  email: string;
  timezone: string;
  subscriptionStatus?: SubscriptionStatus | undefined;

  constructor(data: IRestApiUser) {
    this.id = data.id
    this.name = data.name
    this.email = data.email
    this.timezone = data.timezone
  }

  setSubscriptionStatus(data: any): void {
    this.subscriptionStatus = data
  }
}
