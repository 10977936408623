






























































































































































































































import Component from 'vue-class-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import BaseProjectView from '@/views/BaseProjectView'
import { Report } from '@/models/Report'
import { RestApiError } from '@/providers/RestApiError'
import { IDeviceVariables } from '@/models/Device'
import { MixedChart } from '@/models/MixedChart'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Reports extends BaseProjectView {
  get tableFields(): any[] {
    const fields = [];

    if (["xs", "sm"].includes(this.appWindowStore.currentBreakPoint)) {
      fields.push({
        key: 'small-name',
        label: 'Name',
        sortByFormatted: true,
        sortable: true,
        formatter: (value: string, key: number, item: Report) =>
         `${item.name}<br/><span class="font-weight-lighter font-size-smaller"><i>${item.status.toUpperCase()}</i></span>`,
      });
    } else {
      fields.push({
        key: 'name',
        label: 'Name',
        sortable: true,
      });
      fields.push({
        key: 'status',
        label: 'Status',
        sortable: true,
        formatter: (value: string) => value.toUpperCase(),
      });
    }

    fields.push({
      key: 'reference',
      label: 'Reference',
      formatter: (value: string, key: number, item: Report) => {
        if (item.isRelatedToDevice) {
          return `${item.deviceName}<br/><span class="font-weight-lighter font-size-smaller"><i>Blackbox</i></span>`
        } else {
          return `${item.mixedChartName}<br/><span class="font-weight-lighter font-size-smaller"><i>Mixed Chart</i></span>`
        }
      }
    });
    fields.push({
      key: 'actions',
      label: 'Actions',
    });

    return fields
  }

  get deleteModalContent(): string {
    return `Are you sure you want remove the report "${this.selectedReport?.name}" from the project "${this.project.name}"?`
  }

  get canCreateReport(): boolean {
    if (this.isLoading) return true;
    if (this.validAllDevicesVariables.length === 0) return false;
    
    return true;
  }

  get validAllDevicesVariables(): IDeviceVariables[] {
    return this.allDevicesVariables.filter(x => x.variables.length > 0)
  }

  get isLoggedUserOwnerOfReport(): boolean {
    if (this.selectedReport == null) return false;

    return this.selectedReport.userCreatorId == this.loggedUserId
  }

  reports: Report[] = [];
  allDevicesVariables: IDeviceVariables[] = [];
  allMixedCharts: MixedChart[] = [];
  selectedReport: Report | null = null;

  formName: string | null = null;
  formDescription: string | null = null;
  formDeviceId: number | null = null;
  formDeviceName: string | null = null;
  formMixedChartId: number | null = null;
  formMixedChartName: string | null = null;

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.fetchData)
  }

  async mounted(): Promise<void> {
    await this.fetchData()
  }

  async fetchData(): Promise<void> {
    if (this.project == null) return;
    
    const promises: any[] = [];
    promises.push(this.apiProvider.getAllReports(this.project.id));
    promises.push(this.apiProvider.getAllDeviceAndVariablesList(this.project.id));
    if (this.plexusStore.project && this.plexusStore.project.allDevicesCount >= 2) {
      promises.push(this.apiProvider.getAllMixedCharts(this.project.id));
    }

    try {
      const data = await Promise.all(promises);

      this.reports = data[0];
      this.allDevicesVariables = data[1];
      if (this.plexusStore.project && this.plexusStore.project.allDevicesCount >= 2) {
        this.allMixedCharts = data[2];
      }

      if (this.allDevicesVariables != null && this.allDevicesVariables.length > 0) {
        this.formDeviceId = this.allDevicesVariables[0].deviceId
        this.formDeviceName = this.allDevicesVariables[0].deviceName

        if (this.allMixedCharts.length > 0) {
          this.formDeviceId = null;
          this.formDeviceName = "Select a device ..";
          this.formMixedChartName = ".. or a mixed chart";
        }
      }
    } catch (error) {
      this.handleRestApiException(error as RestApiError)
    }
  }

  async handleUpsert(bvModalEvt: Event): Promise<void> {
    // Prevent modal from closing
    bvModalEvt.preventDefault()
    await this.handleUpsertSubmit()
  }

  async handleUpsertSubmit(): Promise<void> {
    const valid = await (this.$refs.upsertRules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    if (this.selectedReport != null) {
      await this.updateReport()
    } else {
      if (this.formDeviceId == null && this.formMixedChartId == null) {
        this.makeWarningToast(`Please select a device ${this.allMixedCharts.length > 0 ? 'or a mixed chart ' : ''}to continue.`, 'Bad Request')
        return
      }
      
      await this.createReport()
    }
  }

  async updateReport(): Promise<void> {
    if (this.selectedReport == null) return;

    this.apiProvider.updateReport(this.project.id, this.selectedReport.id, {
      name: this.formName as string,
      description: this.formDescription as string,
    })
      .then((updatedReport: Report) => {
        const receivedUpdateDevice = this.reports.find(x => x.id === updatedReport.id);
        if (receivedUpdateDevice != null) {
          receivedUpdateDevice.update(updatedReport);
        }

        this.closeUpsertDialog()
        this.makeToast('Report updated successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  async createReport(): Promise<void> {
    this.apiProvider.createReport(this.project.id, {
      name: this.formName as string,
      description: this.formDescription as string,
      device_id: this.formDeviceId,
      mixed_chart_id: this.formMixedChartId,
    })
      .then((createdReport: Report) => {
        this.reports.push(createdReport)
        this.closeUpsertDialog()
        this.makeToast('Report created successfully. Now you can configure its sections')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  closeUpsertDialog(): void {
    this.resetModal()
    this.$nextTick(() => {
      this.$bvModal.hide('modal-upsert-report')
    })
  }

  resetModal(): void {
    this.selectedReport = null
    this.formName = null
    this.formDescription = null

    if (this.allDevicesVariables != null && this.allDevicesVariables.length > 0) {
      this.formDeviceId = this.allDevicesVariables[0].deviceId
      this.formDeviceName = this.allDevicesVariables[0].deviceName
    } else {
      this.formDeviceId = null
      this.formDeviceName = null
    }
  }

  modalSelectDevice(device: IDeviceVariables | null): void {
    this.formDeviceId = device?.deviceId || null
    this.formDeviceName = device?.deviceName || null
    this.formMixedChartId = null
    this.formMixedChartName = null
  }

  modalSelectMixedChart(mixedChart: MixedChart | null): void {
    this.formDeviceId = null
    this.formDeviceName = null
    this.formMixedChartId = mixedChart?.id || null
    this.formMixedChartName = mixedChart?.name || null
  }

  editReport(report: Report): void {
    this.selectedReport = report.clone()

    this.formName = this.selectedReport.name
    this.formDescription = this.selectedReport.description
    this.formDeviceId = this.selectedReport.deviceId
    this.formDeviceName = this.selectedReport.deviceName

    this.$nextTick(() => {
      this.$root.$bvModal.show('modal-upsert-report')
    })
  }

  setReportStatus(status: string): void {
    if (this.selectedReport == null) return;

    this.apiProvider.updateReportStatus(this.project.id, this.selectedReport.id, status)
      .then((updatedReport: Report) => {
        this.selectedReport?.update(updatedReport)

        const receivedUpdateDevice = this.reports.find(x => x.id === updatedReport.id);
        if (receivedUpdateDevice != null) {
          receivedUpdateDevice.update(updatedReport);
        }

        this.makeToast('Report status updated successfully')
        this.$root.$bvModal.hide('modal-upsert-report')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  openDeleteDialog(report: Report): void {
    this.selectedReport = report.clone()
    this.$root.$bvModal.show('modal-delete-report')
  }

  closeDeleteDialog(): void {
    this.selectedReport = null
    this.$root.$bvModal.hide('modal-delete-report')
  }

  async deleteReport(): Promise<void> {
    if (this.selectedReport == null) return

    const id = this.selectedReport.id
    this.apiProvider.deleteReport(this.project.id, id)
      .then(() => {
        const index = this.reports.findIndex(x => x.id === id)
        if (index > -1) this.reports.splice(index, 1)
        
        this.closeDeleteDialog()
        this.makeToast('Report deleted successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }
}
