import { render, staticRenderFns } from "./FlexSpinner.vue?vue&type=template&id=2c5d5533&scoped=true&"
import script from "./FlexSpinner.vue?vue&type=script&lang=ts&"
export * from "./FlexSpinner.vue?vue&type=script&lang=ts&"
import style0 from "./FlexSpinner.vue?vue&type=style&index=0&id=2c5d5533&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5d5533",
  null
  
)

export default component.exports