import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import * as env from 'env-var'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { RestApiError } from './RestApiError';

export default class BaseRestApiProvider {
  public isLoading: boolean;
  public isResponding: boolean;
  protected axiosInstance: AxiosInstance;
  protected authToken?: string;
  protected timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  protected timezoneOffset: number = new Date().getTimezoneOffset() * 60;

  setLoading(value: boolean): void {
    this.isLoading = value
  }

  setResponding(value: boolean): void {
    this.isResponding = value
  }

  constructor() {
    this.isLoading = false
    this.isResponding = false
    this.axiosInstance = axios.create({
      baseURL: env.get('VUE_APP_BASE_API_URL').required().asString(),
    })
  }

  acquireAuthToken(authToken?: string): void {
    this.authToken = authToken
  }

  protected addTimezoneHeaderMiddleware(): void {
    this.axiosInstance.interceptors.request.use((config: any) => {
      /* eslint-disable no-param-reassign */
      config.headers.Timezone = this.timezone;
      /* eslint-disable no-param-reassign */
      config.headers.TimezoneOffset = this.timezoneOffset;
      return config
    }, error => {
      console.error(error)
      throw error
    })
  }

  protected addAuthenticationMiddleware(): void {
    // console.log('addAuthenticationMiddleware');
    this.axiosInstance.interceptors.request.use((config: any) => {
      if (this.authToken == null) {
        console.warn('Trying to call API with null authToken.')        
      } else {
        /* eslint-disable no-param-reassign */
        config.headers.Authorization = `Bearer ${this.authToken}`
      }
      return config
    }, error => {
      console.error(error)
      throw error
    })

    createAuthRefreshInterceptor(
      this.axiosInstance,
      failedRequest => {
        console.warn('Failed request', failedRequest)
        
        return new Promise(resolve => {
          setTimeout(() => {
            console.log(`'Re-trying after ${env.get('VUE_APP_RETRY_INTERVAL_FOR_401').asIntPositive()}ms'`)
            resolve(true)
          }, env.get('VUE_APP_RETRY_INTERVAL_FOR_401').asIntPositive())
        })
      },
    )
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  static composeCustomError(error: any): RestApiError {
    return {
      errorJson: error.toJSON(),
      responseExists: error.response != null,
      responseErrorCode: error.response != null ? error.response.status : -1,
      errorMessage: error.response != null ? (error.response.data != null ? error.response.data : error.response.statusText) : "Network error / Unexpected error"
    }
  }
}
