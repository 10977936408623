

























































































































import Component from 'vue-class-component'
import { forEach } from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import BaseView from '@/views/BaseView'
import { RestApiError } from '@/providers/RestApiError'
import { User } from '@/models/User'

@Component({
  components: {
    ValidationProvider, ValidationObserver,
  },
})
export default class Profile extends BaseView {
  user: User | null = null;

  allTimezones: string[] = [];
  formCurrentTimezone: string | null = null;
  formName: string | null = null;

  get allTimezonesGrouped(): Record<string, string[]> {
    const grouped: Record<string, string[]> = { };

    if (this.allTimezones == null || this.allTimezones.length === 0)
      return grouped;
    
    forEach(this.allTimezones, (value: string) => {
      const parts = value.split("/");
      const area = parts.length > 2 ? `${parts[0]}/${parts[1]}` : parts[0];
      // const zone = parts.length > 2 ? parts[2] : parts[1];
      const zone = value;
      if (grouped[area] == null) {
        grouped[area] = [zone];
      } else {
        grouped[area].push(zone);
      }
    });
    // console.log(grouped);
    
    return grouped;
  }

  async mounted(): Promise<void> {
    await this.fetchData()
  }

  created(): void {
    this.appConfigStore.UPDATE_NAV_MENU_HIDDEN(false)
  }

  async fetchData(): Promise<void> {
    const promises: Promise<any>[] = [
      this.apiProvider.getMe(),
      this.apiProvider.getAllTimezones(),
    ];
    return Promise.all(promises)
      .then((data: any) => {
        const user = data[0]
        this.user = user
        this.formName = user.name
        this.formCurrentTimezone = user.timezone

        const allTz = data[1]
        this.allTimezones = allTz
      })
      .catch((error: RestApiError) => this.handleRestApiException(error));
  }

  async savePersonalInformations(): Promise<void> {
    const valid = await (this.$refs.rules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    const user = await this.apiProvider.updateMe({
      name: this.formName as string,
      timezone: this.formCurrentTimezone === 'Default browser timezone' ? 'NULL' : this.formCurrentTimezone as string,
    })
    
    this.plexusStore.SET_USER(user)
    this.makeToast('Personal informations saved successfully')
  }

  selectTimezone(timezone: string): void {
    this.formCurrentTimezone = timezone
  }

  goToShop(): void {
    console.log('TBD :)');
    
  }
}
