















































































import Component from 'vue-class-component'

import BaseProjectView from '@/views/BaseProjectView'
import { Backup } from '@/models/Backup'
import { RestApiError } from '@/providers/RestApiError'

@Component
export default class Backups extends BaseProjectView {
  get tableFields(): any[] {
    const fields = [];

    if (["xs", "sm"].includes(this.appWindowStore.currentBreakPoint)) {
      fields.push({
        key: 'small-name',
        label: 'name',
        sortByFormatted: true,
        sortable: true,
        formatter: (value: string, key: number, item: Backup) =>
         `${item.name}<br/><span class="font-weight-lighter font-size-smaller">${item.deviceName}<br/><span class="font-weight-lighter font-size-smaller">${this.dateToLocaleStringWithUserTimezone(item.createdAt)}</span>`,
      });
    } else {
      fields.push({
        key: 'name',
        sortable: true,
      });
      fields.push({
        key: 'deviceName',
        sortable: true,
      });
      fields.push({
        key: 'createdAt',
        label: 'Creation date',
        formatter: (value: Date) => this.dateToLocaleStringWithUserTimezone(value),
      });
    }

    fields.push({
      key: 'actions',
      label: 'Actions',
    });

    return fields
  }

  get modalContent(): string {
    return `Are you sure you want remove the backup "${this.selectedBackup?.name}" from the project "${this.project.name}"?`
  }

  backups: Backup[] = [];
  selectedBackup: Backup | null = null;

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.fetchData)
  }

  async mounted(): Promise<void> {
    await this.fetchData()
  }

  async fetchData(): Promise<void> {
    if (this.project == null || !this.amiSupervisor) return;

    return this.apiProvider.getAllBackups(this.project.id)
      .then((data: Backup[]) => {
        this.backups = data
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))    
  }

  download(backup: Backup): void {
    this.apiProvider.downloadBackup(this.project.id, backup.id)
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${backup.name}.zip`);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
  }

  async deleteBackup(): Promise<void> {
    if (this.selectedBackup == null) return

    const id = this.selectedBackup.id
    this.apiProvider.deleteBackup(this.project.id, id)
      .then(() => {
        const index = this.backups.findIndex(x => x.id === id)
        if (index > -1) this.backups.splice(index, 1)
        
        this.makeToast('Backup cleared successfully')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error))
  }

  openDeleteDialog(backup: Backup): void {
    this.selectedBackup = backup
    this.$root.$bvModal.show('modal-delete-backup')
  }
}
