/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import lodash from 'lodash'

import { UpsertReportSectionOutputPayload } from "@/providers/RestApiPayloads";

export const VARIABLE_METADATA_ALL_AVAILABLE_FIELDS = [
  'start_time.ts', 'start_time.utc', 'start_time.human',
  'end_time.ts', 'end_time.utc', 'end_time.human',
  'time_range.seconds', 'time_range.minutes', 'time_range.hours', 'time_range.days',
  'first_value', 'last_value', 'avg', 'sum', 'avg_nozero', 'sum_nozero',
  'on_off.on_time', 'on_off.on_time_percent', 'on_off.off_time', 'on_off.off_time_percent', 'on_off.off_on_variations', 'on_off.on_off_variations',
  'max.value', 'max.ts', 'max.utc', 'max.human',
  'max_nozero.value', 'max_nozero.ts', 'max_nozero.utc', 'max_nozero.human',
  'min.value', 'min.ts', 'min.utc', 'min.human',
  'min_nozero.value', 'min_nozero.ts', 'min_nozero.utc', 'min_nozero.human',
];

export const VARIABLE_METADATA_AVAILABLE_RANGE_DELIMITERS = [
  'start_time.ts',
  'end_time.ts',
  'max.ts',
  'max_nozero.ts',
  'min.ts',
  'min_nozero.ts',
];

export const METADATA_AVAILABLE_FORMULA_VALUES = [
  'start_time.utc', 'start_time.human', 'start_time.ts',
  'end_time.utc', 'end_time.human', 'end_time.ts',
  'time_range.seconds', 'time_range.minutes', 'time_range.hours', 'time_range.days',
];

export const VARIABLE_METADATA_AVAILABLE_FORMULA_VALUES = [
  'first_value', 'last_value', 'avg', 'sum', 'avg_nozero', 'sum_nozero',
  'on_off.on_time', 'on_off.on_time_percent', 'on_off.off_time', 'on_off.off_time_percent', 'on_off.off_on_variations', 'on_off.on_off_variations',
  'max.value', 'max.utc', 'max.human',
  'max_nozero.value', 'max_nozero.utc', 'max_nozero.human',
  'min.value', 'min.utc', 'min.human',
  'min_nozero.value', 'min_nozero.utc', 'min_nozero.human',
];

export interface IReportLastExecution {
  ts: number;
  iso8601: string;
  user_id: number;
  user_email: string; 
}

export interface IReportSection {
  id?: number;
  sorting?: number;
  name: string;
  start: string;
  end: string;
  outputs: IReportSectionOutput[];
}

export interface IReportSectionOutput {
  name: string;
  formula: string;
  key?: string;
  sorting?: number;
  unit?: string;
  isVisible: boolean;
}

export class ReportSectionOutput {
  name: string;
  formula: string;
  key?: string;
  sorting?: number;
  unit?: string;
  isVisible: boolean;
  _original: ReportSectionOutput | null = null;

  get isDirty(): boolean {
    if (this._original == null) return true
    return !this.equalsTo(this._original)
  }

  constructor(data: IReportSectionOutput, storeOriginalValue = true) {
    this.name = data.name;
    this.formula = data.formula;
    this.sorting = data.sorting;
    this.unit = data.unit;
    this.key = data.key;
    this.isVisible = data.isVisible == null ? true : data.isVisible;

    if (storeOriginalValue) {
      this._original = this.clone()
    }
  }

  get formulaVariablesCustomKeys(): string[] {
    return this.formulaVariablesAndMetas.filter(x => {
      return !VARIABLE_METADATA_ALL_AVAILABLE_FIELDS.includes(x) && x.split('.').length === 1
    });
  }

  get formulaVariables(): string[] {
    return this.formulaVariablesAndMetas.filter(x => !METADATA_AVAILABLE_FORMULA_VALUES.includes(x));
  }

  get formulaVariablesAndMetas(): string[] {
    const result: string[] = [];
    
    let i = 0;
    do { 
      if (this.formula[i] == '$' && this.formula[i + 1] == '{') {
        for (let j = i + 2; j < this.formula.length; j++) {
          if (this.formula[j] == '}') {
            result[result.length] = this.formula.slice(i + 2, j);
            i = j + 1;
            break;
          }
        }
      }
    } while (++i < this.formula.length - 1);
  
    return result;
  }

  /**
   * checks if formula variables are correct (${xxxxx})
   * checks if there's any character outside formulas (foo/1)
   * 
   * @param deviceVariablesAndPreviousOutputKeys 
   * @returns true or error message string
   */
  validate(deviceVariablesAndPreviousOutputKeys: string[]): boolean | string {
    const variables = this.formulaVariablesAndMetas;
    for (let i = 0; i < variables.length; i += 1) {
      if (METADATA_AVAILABLE_FORMULA_VALUES.includes(variables[i])) continue;
      
      const varKey = variables[i].split('.')[0];
      const varValue = variables[i].substring(varKey.length + 1);
      
      if (deviceVariablesAndPreviousOutputKeys.includes(varKey)) continue;
      if (VARIABLE_METADATA_AVAILABLE_FORMULA_VALUES.includes(varValue)) continue;

      return `"${this.name}" formula field "${variables[i]}" is not valid`;
    }

    const variablesWithWrapper = variables.map(x => '${' + x + '}');
    let formulaWithNoVariables = this.formula;
    variablesWithWrapper.forEach(variable => {
      formulaWithNoVariables = formulaWithNoVariables.replace(variable, '');
    });

    // https://stackoverflow.com/a/66808435
    const alphaUnicodeRegex = /[\p{L}]+/u;
    if (formulaWithNoVariables.match(alphaUnicodeRegex)) {
      return `"${this.name}" formula contains invalid characters outside variable brackets \${}`;
    }

    return true;
  }

  clone(): ReportSectionOutput {
    return new ReportSectionOutput({
      name: this.name,
      sorting: this.sorting,
      unit: this.unit,
      formula: this.formula,
      key: this.key,
      isVisible: this.isVisible,
    }, false)
  }

  equalsTo(version: ReportSectionOutput): boolean {
    if (this.name !== version.name) return false
    if (this.sorting != version.sorting) return false
    if (this.unit != version.unit) return false
    if (this.formula !== version.formula) return false
    if (this.key != version.key) return false
    if (this.isVisible != version.isVisible) return false

    return true
  }

  toJsonPayload(): UpsertReportSectionOutputPayload {
    return {
      name: this.name,
      sorting: this.sorting,
      unit: this.unit,
      formula: this.formula.replaceAll(" ", "").replaceAll("\n", "").replaceAll(",", "."),
      key: this.key,
      isVisible: this.isVisible,
    }
  }
}

export interface IReportSectionValidationOutput {
  isValid: boolean;
  outputKeys?: string[];
  error?: string;
}

export class ReportSection {
  id: number;
  sorting?: number;
  name: string;
  start: string;
  end: string;
  outputs: ReportSectionOutput[];
  _original: ReportSection | null = null;

  get startVariable(): string {
    return this.start === 'chart_start' ? 'chart_start' : this.start.split('.')[0]
  }

  get endVariable(): string {
    return this.end === 'chart_end' ? 'chart_end' : this.end.split('.')[0]
  }

  get isDirty(): boolean {
    if (this._original == null) return true
    if (this.id <= 0) return true
    return !this.equalsTo(this._original)
  }

  get allOutputFormulaVariablesKeys(): string[] {
    const vars = this.outputs
      .flatMap(x => x.formulaVariables)
      .filter(x => x.split('.').length > 1)
      .map(x => x.split('.')[0]);
    
    return lodash.uniq(vars);
  }

  get allOutputKeys(): string[] {
    const vars = this.outputs
      .filter(x => x.key != null && x.key.length > 0)
      .flatMap(x => x.key as string);
    
    return lodash.uniq(vars);
  }

  get canBeSaved(): boolean {
    if (this.name == null || this.name.length === 0) return false;
    if (this.start == null || this.start.length === 0) return false;
    if (this.end == null || this.end.length === 0) return false;
    if (this.outputs == null || this.outputs.length === 0) return false;

    for (let i = 0; i < this.outputs.length; i += 1) {
      if (this.outputs[i].formula == null || this.outputs[i].formula.length === 0) return false
      if (this.outputs[i].name == null || this.outputs[i].name.length === 0) return false
      if (this.outputs[i].key != null && (this.outputs[i].key as string).length > 0 && !(this.outputs[i].key as string).match(/^([a-zA-Z0-9]+)$/)) return false
    }

    return true;
  }

  get isSetToChartRange(): boolean {
    return this.start === 'chart_start' && this.end === 'chart_end'
  }

  constructor(data: IReportSection, storeOriginalValue = true) {
    this.id = data.id != null ? data.id : 0
    this.sorting = data.sorting
    this.name = data.name
    this.start = data.start
    this.end = data.end
    this.outputs = data.outputs != null ? data.outputs.map(x => new ReportSectionOutput(x)) : []

    if (storeOriginalValue) {
      this._original = this.clone()
    }
  }

  equalsTo(version: ReportSection): boolean {
    if (this.name !== version.name) return false
    if (this.start !== version.start) return false
    if (this.end !== version.end) return false

    if (this.outputs.length != (this._original?.outputs.length || 0)) return false

    for (let i = 0; i < this.outputs.length; i += 1) {
      if (this.outputs[i].isDirty) return false
    }

    return true
  }

  validateTimeRange(deviceVariables: string[]): boolean | string {
    if (this.start === 'chart_start' && this.end === 'chart_end') return true;

    if (this.start !== 'chart_start') {
      const varKey = this.start.split('.')[0];
      const varValue = this.start.substring(varKey.length + 1);
      if (!deviceVariables.includes(varKey) || !VARIABLE_METADATA_AVAILABLE_RANGE_DELIMITERS.includes(varValue)) {
        return `"${this.name}" start value is not valid`;
      }
    }

    if (this.end !== 'chart_end') {
      const varKey = this.end.split('.')[0];
      const varValue = this.end.substring(varKey.length + 1);
      if (!deviceVariables.includes(varKey) || !VARIABLE_METADATA_AVAILABLE_RANGE_DELIMITERS.includes(varValue)) {
        return `"${this.name}" end value is not valid`;
      }
    }

    return true;
  }

  validateOutputs(availableKeys: string[]): boolean | string {
    const outputKeys: string[] = []
    for (let i = 0; i < this.outputs.length; i += 1) {
      const outputValidationResult = this.outputs[i].validate([...availableKeys, ...outputKeys]);

      if (outputValidationResult !== true) {
        return outputValidationResult
      }

      if (this.outputs[i].key != null && (this.outputs[i].key as string).length > 0) outputKeys.push((this.outputs[i].key as string))
    }

    return true
  }

  validate(availableKeys: string[]): IReportSectionValidationOutput {
    const timeRangeValidationResult = this.validateTimeRange(availableKeys);
    if (timeRangeValidationResult !== true) {
      return {
        isValid: false,
        error: timeRangeValidationResult as string,
      };
    }

    const outputsValidationResult = this.validateOutputs(availableKeys);
    if (outputsValidationResult !== true) {
      return {
        isValid: false,
        error: outputsValidationResult as string,
      };
    }

    return {
      isValid: true,
    };
  }

  clone(): ReportSection {
    return new ReportSection({
      id: this.id,
      sorting: this.sorting,
      name: this.name,
      start: this.start,
      end: this.end,
      outputs: this.outputs.map(x => x.clone()),
    }, false)
  }

  revertToOriginal(): void {
    if (this._original == null) {
      return
    }

    this.id = this._original.id
    this.sorting = this._original.sorting
    this.name = this._original.name
    this.start = this._original.start
    this.end = this._original.end
    this.outputs = this._original.outputs != null ? this._original.outputs.map(x => new ReportSectionOutput(x)) : []
  }

  update(data: ReportSection): void {
    this.id = data.id
    this.sorting = data.sorting
    this.name = data.name
    this.start = data.start
    this.end = data.end
    this.outputs = data.outputs.map(x => new ReportSectionOutput(x))
    this._original = data.clone()
  }
}

export interface IRestApiReport {
  id: number;
  name: string;
  status: string;
  sorting: number;
  description: string;
  user_creator_id: number;
  user_creator_name: string;
  device_id: number;
  device_name: string;
  mixed_chart_id: number;
  mixed_chart_name: string;
  sections: IReportSection[];
  // created_at: string;                     // ISO string
  // updated_at: string;                     // ISO string
  last_execution: IReportLastExecution | null;
}

export class Report {
  id: number;
  name: string;
  status: string;
  sorting: number;
  description: string;
  userCreatorId: number;
  userCreatorName: string;
  deviceId: number;
  deviceName: string;
  mixedChartId: number;
  mixedChartName: string;
  sections: ReportSection[];
  // createdAt?: Date;
  // updatedAt?: Date;
  lastExecution?: IReportLastExecution;

  get isRelatedToDevice(): boolean {
    return this.deviceId != null;
  }

  get isRelatedToMixedChart(): boolean {
    return !this.isRelatedToDevice;
  }

  constructor(data: IRestApiReport) {
    this.id = data.id
    this.name = data.name
    this.status = data.status
    this.sorting = data.sorting
    this.description = data.description
    this.userCreatorId = data.user_creator_id
    this.userCreatorName = data.user_creator_name
    this.deviceId = data.device_id
    this.deviceName = data.device_name
    this.mixedChartId = data.mixed_chart_id
    this.mixedChartName = data.mixed_chart_name
    // this.createdAt = data.created_at != null ? new Date(data.created_at) : undefined
    // this.updatedAt = data.updated_at != null ? new Date(data.updated_at) : undefined
    this.lastExecution = data.last_execution != null ? data.last_execution : undefined

    this.sections = (data.sections != null) ? data.sections.map(x => new ReportSection(x)) : []
  }

  validate(deviceVariables: string[]): boolean | string {
    if (this.sections.length === 0) return false

    let deviceVariablesAndOutputKeys = [...deviceVariables];
    for (let i = 0; i < this.sections.length; i += 1) {
      const sectionValidationResult = this.sections[i].validate(deviceVariablesAndOutputKeys)
      if (!sectionValidationResult.isValid) {
        return sectionValidationResult.error as string;
      }
      deviceVariablesAndOutputKeys = [...deviceVariablesAndOutputKeys, ...this.sections[i].allOutputKeys]
    }

    return true;
  }

  update(report: Report): void {
    this.name = report.name
    this.description = report.description
    this.status = report.status
  }

  clone(): Report {
    return new Report({
      id: this.id,
      name: this.name,
      status: this.status,
      sorting: this.sorting,
      description: this.description,
      user_creator_id: this.userCreatorId,
      user_creator_name: this.userCreatorName,
      device_id: this.deviceId,
      device_name: this.deviceName,
      mixed_chart_id: this.mixedChartId,
      mixed_chart_name: this.mixedChartName,
      // createdAt: this.created_at != null ? new Date(this.created_at) : undefined,
      // updatedAt: this.updated_at != null ? new Date(this.updated_at) : undefined,
      last_execution: this.lastExecution || null,
      sections: this.sections.map(x => x.clone()),
    })
  }
}
