



























































































































































import BaseProjectComponent from '@/components/BaseProjectComponent'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import AceEditor from 'vuejs-ace-editor';
import { Ace } from 'ace-builds';

import { METADATA_AVAILABLE_FORMULA_VALUES, ReportSection, ReportSectionOutput, VARIABLE_METADATA_AVAILABLE_FORMULA_VALUES } from '@/models/Report'
import { IDeviceVariables } from '@/models/Device';

const VARIABLE_METADATA_TO_APPEND_FOR_RANGE_DELIMITERS = [
  'max.ts',
  'max_nozero.ts',
  'min.ts',
  'min_nozero.ts',
];

@Component({
  components: {
    ValidationProvider, ValidationObserver, AceEditor
  },
})
export default class ReportSectionCard extends BaseProjectComponent {
  @Prop() readonly section!: ReportSection;
  @Prop() readonly previousSections!: ReportSection[];
  @Prop() readonly deviceVariables!: IDeviceVariables;
  @Prop() readonly idx!: number;
  @Prop() readonly sectionCount!: number;
  canLoadAceEditor = false;
  customCompleters: Ace.Completer[] = []

  get staticCompletions(): Ace.Completion[] {
    const completions: Ace.Completion[] = []

    this.deviceVariables.variables.map(variable => {
      VARIABLE_METADATA_AVAILABLE_FORMULA_VALUES.map(val => {  
        completions.push({
          value: `$\{${variable}.${val}}`,
          name: `${variable}.${val}`,
          caption: `${variable}.${val}`,
          score: 1,
        })
      })
    });

    METADATA_AVAILABLE_FORMULA_VALUES.map(val => {  
      completions.push({
        value: `$\{${val}}`,
        name: `${val}`,
        caption: `${val}`,
        score: 2,
      })
    });

    this.previousSections.flatMap(x => x.allOutputKeys).map(val => {  
      completions.push({
        value: `$\{${val}}`,
        name: `${val}`,
        caption: `${val}`,
        score: 3,
      })
    });
    
    return completions
  }

  get cardContainerClasses(): string {
    return this.idx === (this.sectionCount - 1) ? 'last' : ''
  }

  get startOptions(): string[] {
    const options = ['chart_start'];
    this.deviceVariables.variables.forEach(x => {
      VARIABLE_METADATA_TO_APPEND_FOR_RANGE_DELIMITERS.forEach(y => {
        options.push(`${x}.${y}`)
      })
    })
    return options
  }

  get endOptions(): string[] {
    const options = ['chart_end'];
    this.deviceVariables.variables.forEach(x => {
      VARIABLE_METADATA_TO_APPEND_FOR_RANGE_DELIMITERS.forEach(y => {
        options.push(`${x}.${y}`)
      })
    })
    return options
  }

  aceEditorInit(): void {
    require('brace/ext/language_tools');  //language extension prerequisite...
    require('brace/mode/text');           //language
    require('brace/theme/github');
  }

  mounted(): void {
    setTimeout(() => {
      this.canLoadAceEditor = true
      this.$nextTick(() => this.resetAceCustomCompleters())
    }, 50)
  }

  resetAceCustomCompleters(): void {
    this.customCompleters = []
    const collectedKeys: string[] = []

    this.section.outputs.forEach((output, i) => {
      const outputCompletions = [...this.staticCompletions];

      collectedKeys.forEach(k => {
        outputCompletions.push({
          value: `$\{${k}}`,
          name: `${k}`,
          caption: `${k}`,
          score: 4,
        })
      });
      
      if (output.key != null) collectedKeys.push(output.key as string);

      this.customCompleters.push({
        getCompletions: (editor: Ace.Editor, session: Ace.EditSession, pos: Ace.Point, prefix: string, callback: Ace.CompleterCallback) => {
          callback(null, outputCompletions)
        }
      });

      this.$nextTick(() => {
        (this.$refs["ace-editor"] as any[])[i].editor.completers = [this.customCompleters[i]];
        (this.$refs["ace-editor"] as any[])[i].editor.setOptions({
          enableBasicAutocompletion: [this.customCompleters[i]],
          enableLiveAutocompletion: [this.customCompleters[i]],
        });
      })
    });
  }

  /**
   * Si occupa anche di gestire gli autocompletamenti di ACE editor, basandosi sugli output.keys degli output precedenti in ordine di definizione
   */
  addOutput(): void {
    this.section.outputs.push(new ReportSectionOutput({
      name: "Output Name",
      formula: "${variable.field.value} / 100",
      isVisible: true,
    }, false))
    this.resetAceCustomCompleters()
  }

  removeOutput(outputIndex: number): void {
    this.section.outputs.splice(outputIndex, 1);
    this.resetAceCustomCompleters();
  }

  async remove(): Promise<void> {
    this.$emit('delete');
  }

  async upsert(): Promise<void> {
    const valid = await (this.$refs.rules as InstanceType<typeof ValidationObserver>).validate()
    if (!valid) {
      return
    }

    const validationResult = this.section.validate([...this.deviceVariables.variables, ...this.previousSections.flatMap(x => x.allOutputKeys)]);
    if (!validationResult.isValid) {
      this.makeWarningToast(validationResult.error as string);
      return;
    }

    this.apiProvider.upsertReportSection(this.project.id, Number.parseInt(this.$route.params.reportId, 10), {
      name: this.section.name,
      start: this.section.start,
      end: this.section.end,
      section_id: this.section.id === 0 ? undefined : this.section.id,
      device_id: this.deviceVariables.deviceId,
      outputs: this.section.outputs.map(o => o.toJsonPayload())
    })
      .then(data => {
        this.section.update(data);
        this.makeToast();
      })
      .catch(error => {
        if (error != null && error.errorMessage != null) {
          this.makeDangerToast(error.errorMessage)
        }
      })
  }

  toggleVisibility(outputIndex: number): void {
    this.section.outputs[outputIndex].isVisible = !this.section.outputs[outputIndex].isVisible;
  }
}
