















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import Component from 'vue-class-component'
import lodash from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import BaseChartView from '@/views/BaseChartView'
import ReportEvaluationCard from '@/components/ReportEvaluationCard.vue'
import { Blackbox } from '@/models/Blackbox'
import { RestApiError } from '@/providers/RestApiError'
import { Report } from '@/models/Report'
import { LAST_HOUR, LAST_SEVEN_DAYS, LAST_SIX_HOURS, LAST_TEN_MINUTES, LAST_THIRTY_DAYS, LAST_TWENTYFOUR_HOURS, TimeRange } from '@/models/TimeRanges';

interface BlackboxDropdownItem {
  deviceName: number;
  blackboxes: Blackbox[];
}

@Component({
  components: {
    vSelect,
    flatPickr,
    ReportEvaluationCard,
  },
})
export default class Blackboxes extends BaseChartView {
  allTimeRanges: TimeRange[] = [
    LAST_TEN_MINUTES, LAST_HOUR, LAST_SIX_HOURS, LAST_TWENTYFOUR_HOURS, LAST_SEVEN_DAYS, LAST_THIRTY_DAYS, {
      label: "Custom range", isCustom: true
    }
  ]

  blackboxes: Blackbox[] = [];
  reports: Report[] = [];

  get chartDropdownText(): string {
    if (!this.selectedChart) {
      return "Charts";
    }
    if (this.isMobile || this.blackboxDropdownItems.length == 1) {
      return this.selectedChart.name;
    }

    return `${(this.selectedChart as Blackbox).deviceName} / ${this.selectedChart.name}`;
  }
  
  get blackboxDropdownItems(): BlackboxDropdownItem[] {
    if (this.blackboxes == null || this.blackboxes.length === 0) return [];
    
    const blackboxesGroupedByDevice = lodash(this.blackboxes)
      .groupBy('deviceName')
      .map((blackbox: Blackbox, deviceName: number) => {
        return {
          deviceName,
          blackboxes: blackbox
        }
      })
      .value() as unknown as BlackboxDropdownItem[];
    
    return blackboxesGroupedByDevice;
  }

  get reportDropdownItems(): Report[] {
    if (this.selectedChart == null || this.reports == null) return [];
    
    return this.reports.filter(x => x.deviceId === (this.selectedChart as Blackbox)?.deviceId);
  }

  created(): void {
    super.onCreate()

    this.$watch(() => this.$route.params.projectId, this.initPage)
  }

  async mounted(): Promise<void> {
    await this.initPage()
  }

  async initPage(): Promise<void> {
    await this.fetchData()
  }

  async fetchData(): Promise<void> {
    const promises: any[] = [
      this.apiProvider.getAllBlackboxes(this.project.id),
      this.apiProvider.getAllReports(this.project.id),
    ]

    Promise.all(promises)
      .then(results => {
        this.blackboxes = results[0]
        if (this.blackboxes.length > 0) {
          const firstActiveChart = this.blackboxes.find(x => !x.disabled)
          if (firstActiveChart) this.selectChart(firstActiveChart)
        }

        this.reports = results[1].filter((x: Report) => x.status === 'published')
      })
      .catch((error: RestApiError) => this.handleRestApiException(error));
  }

  override async loadChartData(): Promise<void> {
    if (this.selectedChart == null) {
      return
    }
    if (this.chartTimeRange == null) {
      this.chartTimeRange = LAST_HOUR
    }

    this.startZoom = this.chartTimeRange.start
    this.endZoom = this.chartTimeRange.end

    const data = await this.apiProvider.getChartData('blackboxes', this.project.id, this.selectedChart.id, this.chartTimeRange)
      .catch((error: RestApiError) => this.handleRestApiException(error));
      
    if (data == null || data == "") {
      this.makeWarningToast("No data to show for the selected time range")
    } else {
      this.storeDataSnapshot(data as string);
      this.existDataInRange = true
      this.chartLines = (data as string).split("\n")[0].split(",").slice(2)

      if (this.selectedChartLines == null) this.selectedChartLines = JSON.parse(JSON.stringify(this.selectedChart.variables))

      this.drawDygraph(this.currentSnapshot?.data || "");

      this.canZoom = false
      this.onFilterChanged(this.selectedChartLines as string[])
    }
  }
}
